import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Grid, Typography, Switch } from "@mui/material";

import PageHeader from "components/Common/PageHeader";
import ActivityCheckerGraph from "components/ActivityChecker/ActivityCheckerGraph";
import DateScrollBar from "components/ActivityChecker/DateScrollBar";
import useAdminTools from "adminapi";
import { hyphenatedDate, isValidDate } from "helperFuncs/dateFormat";
import "./ActivityChecker.css";

const parseActivityArray = array => (array.filter(timespan => {
	timespan.startTime = new Date(timespan.execute);
	timespan.endTime = new Date(timespan.terminate);
	return isValidDate(timespan.startTime) && isValidDate(timespan.endTime);
}));

const parseOverlapArray = array => (array.filter(timespan => {
	timespan.startTime = new Date(timespan.start_time);
	timespan.endTime = new Date(timespan.end_time);
	return isValidDate(timespan.startTime) && isValidDate(timespan.endTime);
}));

const ActivityChecker = (/*{props}*/) => {
	const api = useAdminTools();
	const params = useParams();

	const checkerRef = React.useRef();
	const [licenseNo,] = React.useState(params.licenseNo);
	const [license, setLicense] = React.useState(null);
	const [title,] = React.useState("Activity Checker: " + licenseNo);

	const [activityData, setActivityData] = React.useState(null);

	const thirtyDaysAgo = () => {
		const start = new Date();
		start.setDate(start.getDate() - 30);
		start.setHours(0, 0, 0, 0);
		return start;
	};

	const tomorrow = () => {
		const end = new Date();
		end.setDate(end.getDate() + 1);
		end.setHours(0, 0, 0, 0);
		return end;
	};

	

	const [startDate, setStartDate] = React.useState(thirtyDaysAgo);
	const [endDate, setEndDate] = React.useState(tomorrow);

	const [viewDays, ] = React.useState(7);

	const [errorState, setErrorState] = React.useState(null);
	const [displayInvalidDateRange, setDisplayInvalidDateRange] = React.useState(false);

	const [scrollPosition, setScrollPosition] = React.useState(() => {
		let startPosition = new Date(endDate);
		startPosition.setDate(startPosition.getDate() - viewDays);
		return startPosition;
	});

	const scrollMax = endDate;

	const [viewRange,] = React.useState(() => {
		let startDate = new Date(endDate);
		startDate.setDate(startDate.getDate() - viewDays);
		return scrollMax.getTime() - startDate.getTime();
	});

	const scrollMin = startDate;

	const [selectStart, setSelectStart] = React.useState(null);
	const [selectEnd, setSelectEnd] = React.useState(null);
	const [onlyOverlaps, setOnlyOverlaps] = React.useState(false);

	const navigate = useNavigate();

	const handleBack = React.useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const handleDateScroll = (newPosition) => {
		if (isValidDate(newPosition)) {
			setScrollPosition(newPosition);
		}
	};

	const handleStartChange = useCallback((newValue) => {
		setStartDate(newValue);
		if(scrollPosition.getTime() < newValue.getTime()) {
			setScrollPosition(newValue);
		}
	},[scrollPosition]);

	const handleEndChange = useCallback((newValue) => {
		setEndDate(newValue);
		let newMax = new Date(newValue);
		newMax.setDate(newMax.getDate() - viewDays);
		if(scrollPosition.getTime() > newMax.getTime()) {
			setScrollPosition(newMax);
		}
	},[scrollPosition, viewDays]);

	React.useEffect(() => {
		if (errorState) {
			return;
		}
		let validRange = (isValidDate(startDate) && isValidDate(endDate)
			&& startDate.getTime() < endDate.getTime());

		setDisplayInvalidDateRange(!validRange);
		if (!validRange) {
			return;
		}
		if (license) {
			//have license data, get activity
			const activityUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/license/${license.license_id
			}/activity?start_date=${hyphenatedDate(startDate)}&end_date=${hyphenatedDate(endDate)}`;

			api.get(activityUrl,{headers: api.noCache}).then(response => {
				parseActivityArray(response.data.data.activities);
				parseOverlapArray(response.data.data.overlaps);
				setActivityData(response.data.data);
			}).catch(error => {
				setErrorState(`Error getting activity: ${error.statusText ?? error?.toString()}`);
			});
		} else {
			//don't have license data, get license data
			const licenseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/license/no/${licenseNo}`;

			api.get(licenseUrl).then(response => {
				setLicense(response.data.data.license);
			}).catch(error => {
				setErrorState(`Error getting license: ${error.statusText ?? error?.toString()}`);
			});
		}
	}, [startDate, endDate, licenseNo, license, errorState, api]);

	const inputBar = React.useMemo(() => <>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div className="selectionBar">
				<Grid container alignItems="center">
					<Grid item sm={4}>
						<DatePicker
							label="Start Date"
							onChange={handleStartChange}
							value={startDate}
							slotProps={{ 
								textField:{
									size:"small",
								}
							}}
						/>
					</Grid>
					<Grid item sm={4}>
						<DatePicker
							label="End Date"
							onChange={handleEndChange}
							value={endDate}
							slotProps={{ 
								textField:{
									size:"small",
								}
							}}
						/>
					</Grid>
					<Grid item sm={4}>
						<Typography>
							Show only overlaps:
							<Switch
								checked={onlyOverlaps}
								onChange={() => { setOnlyOverlaps(x => !x); }}
							/>
						</Typography>
					</Grid>
				</Grid>
			</div>
		</LocalizationProvider>
	</>, [startDate, endDate, onlyOverlaps, handleBack, handleStartChange, handleEndChange, title]);

	let invalidStatusDisplay = null;

	if (errorState !== null) {
		invalidStatusDisplay = <>Error obtaining activity data: {errorState}</>;
	} else if (!isValidDate(startDate)) {
		invalidStatusDisplay = <>Invalid Start Date</>;
	} else if (!isValidDate(endDate)) {
		invalidStatusDisplay = <>Invalid End Date</>;
	} else if (displayInvalidDateRange) {
		invalidStatusDisplay = <>Invalid Date Range Selected</>;
	} else if (activityData === null) {
		invalidStatusDisplay = <>Fetching Activity Data...</>;
	}

	const activity = activityData?.activities;
	const overlaps = activityData?.overlaps;

	const activityMap = React.useMemo(() => {
		const map = new Map();

		if (activity) {
			for (let session of activity) {
				map.set(session.session_name, session);
			}
		}

		return map;

	}, [activity]);

	const selectionTable = React.useMemo(() => {
		if (selectStart && selectEnd && activity) {

			const st = selectStart.getTime();
			const ed = selectEnd.getTime();

			const [first, second] = ed > st ? [st, ed] : [ed, st];

			let toDisplay = [];

			const selectionFilter = timespan => {
				return (timespan.startTime.getTime() > first && timespan.startTime.getTime() < second) ||
					(timespan.endTime.getTime() > first && timespan.endTime.getTime() < second);
			};

			if (onlyOverlaps) {
				toDisplay = overlaps.filter(selectionFilter).flatMap(o =>
					o.sessions.map(activityMap.get)
				);
			} else {
				toDisplay = activity.filter(selectionFilter);
			}

			let times = toDisplay.map(s => { return { name: s.session_name, execute: true, timestamp: s.startTime }; });

			times = times.concat(toDisplay.map(s => { return { name: s.session_name, execute: false, timestamp: s.endTime }; }));

			times.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

			if (times.length > 0) {
				let tRows = [];

				for (let t of times) {
					const cellStyle = genRowStyle(t);
					let activityTds = <td style={cellStyle}>{t.timestamp.toUTCString()}</td>;
					if (t.execute) {
						activityTds = <>
							{activityTds}
							<td style={cellStyle}/>
							<td style={cellStyle}>execute</td>
						</>;
					} else {
						activityTds = <>
							<td style={cellStyle}/>
							{activityTds}
							<td style={cellStyle}>terminate</td>
						</>;
					}
					tRows.push(<tr key={t.name + t.execute}>
						<td style={cellStyle}>{t.name}</td>
						{activityTds}
					</tr>);
				}
				///

				return <table className="activityTable"
					style={{ marginBottom: checkerRef.current?.getBoundingClientRect().height + 20 }}
				>
					<thead>
						<tr>
							<th>Session</th>
							<th>Start Time</th>
							<th>End Time</th>
							<th>Event</th>
						</tr>
					</thead>
					<tbody>
						{tRows}
					</tbody>
				</table>;
			}
		}
		return <div className="instructions">
			Click and Drag the track to select a timespan
		</div>;
	}, [selectEnd, selectStart, onlyOverlaps, activity, overlaps, activityMap]);

	if (invalidStatusDisplay !== null) {
		return <>
			<PageHeader title={title} />
			<div className="activityChecker">
				<div className="activityWrapper">
					<div className="errorMessage">
						{invalidStatusDisplay}
					</div>
				</div>
			</div>
		</>;
	}


	return <>
		<PageHeader title={title} />
		<div className="activityChecker">
			{inputBar}
			{selectionTable}
			<div className="footer" ref={checkerRef}>
				<div className="graphAndScroll">
					<ActivityCheckerGraph
						license={license}
						startDate={startDate}
						endDate={endDate}
						viewDays={viewDays}
						activity={activity}
						overlaps={overlaps}
						position={scrollPosition}

						selectStartState={[selectStart, setSelectStart]}
						selectEndState={[selectEnd, setSelectEnd]}
					/>
					<DateScrollBar key="scroll" setPosition={setScrollPosition}
						min={scrollMin}
						max={scrollMax}
						position={scrollPosition}
						viewRange={viewRange}
						onPositionChange={handleDateScroll}
					/>
				</div>
			</div>
		</div>
	</>;
};

export default ActivityChecker;

const genRowStyle = (activity) => {
	let n = activity.name;

	let r = 0;
	let g = 0;

	// I threw in a little bit of chaos, seen below.
	// This way, similar session names will probably show up as very different colors.

	for (let i = 0; i < n.length; i++) {
		const c = n.charCodeAt(i);
		r += c * (i + 7);
		g += c * (i + 23);
	}

	//The addition keeps the colors within a pallete that doesn't strain the eyes.
	//Green is also always fixed to 255.

	r = r % 161 + 95;
	g = g % 125 + 131;

	return {
		backgroundColor: `rgb(${r},${g},255)`
	};
};