import React, { useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Avatar, Menu, MenuItem, AppBar, Box, Toolbar, IconButton, Typography } from "@mui/material";

import { LoginContext } from "components/Login/loginCommon";
import logo from "static/Brand_Logo.jpg";
import backIcon from "static/backwards.svg";
import "./PageHeader.css";

const PageHeader = ({ title, onBack, noBack }) => {
	const { signOut, picture } = useContext(LoginContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const navigate = useNavigate();

	const backButton = useMemo(()=>{
		if(noBack) {
			return undefined;
		}
		return <div className="navBack">
			<img
				className="backIcon"
				onClick={onBack ?? (()=>navigate(-1))}
				src={backIcon}
				alt="back"
			/>
		</div>;
	},[navigate, onBack, noBack]);

	const handleAvatar = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box className='pageHeader'>
			<AppBar position='static'>
				<Toolbar className='headerToolbar'>
					<Typography variant='h6'>
						<Link to="/">
							<img
								src={logo}
								alt="Advantage Software"
								className='headerLogo'
							/>
						</Link>
					</Typography>
					<br />
					<br />
					{backButton}
					<Typography
						variant='h6'
						component='div'
						className='headerTitle'
					>
						{title}
					</Typography>

					<IconButton
						onClick={handleAvatar}
					>
						<Avatar
							alt={localStorage.getItem("login/name") || "?"}
							src={localStorage.getItem("login/picture") || picture}
						/>
					</IconButton>

					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						className="avatarMenu"
					>
						<MenuItem
							onClick={signOut}
						>
							Logout
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default PageHeader;
