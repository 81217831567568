import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Divider, Grid, Dialog, Button } from "@mui/material";

import "./ChoiceDialog.css";


const ChoiceDialog = ({
	dialogOpen,
	onDialogClose,
	title,
	confirmElement,
	cancelElement,
	children,
}) => {

	const closeAlert = () => {
		onDialogClose(false);
	};

	const handleCloseButton = () => {
		onDialogClose(false);
	};

	const handleCancel = () => {
		onDialogClose(false);
	};

	const handleAccept = () => {
		onDialogClose(true);
	};

	return <Dialog
		open={dialogOpen}
		onClose={closeAlert}
		className="choiceDialog"
	>
		<div className="header">
			<div className="tag" />
			<span className="closeButton">
				<CloseIcon onClick={handleCloseButton} />
			</span>
			<div className="title">
				{title}
			</div>
		</div>
		
		<Divider />

		<div className="content">
			{children}
		</div>

		<Grid container justifyContent="center" columnSpacing={10} rowSpacing={1}>
			<Grid item>
				<Button
					variant='outlined'
					className="confirmButton"
					onClick={handleAccept}
				>
					{confirmElement}
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant='contained'
					className="cancelButton"
					onClick={handleCancel}
				>
					{cancelElement}
				</Button>
			</Grid>
		</Grid>
	</Dialog>;
};

export default ChoiceDialog;
