import { createContext } from "react";
import { jwtDecode, InvalidTokenError } from "jwt-decode";

export const LoginContext = createContext({
	loginAuth: null,
	errorMsg: null,
	setErrorMsg: err => console.log("null login error: ", err),
});

export function ClearSessionData() {
	for (const [key,] of Object.entries(localStorage)) {
		if (key.match(/^(login|search)\/.*/)) {
			localStorage.removeItem(key);
		}
	}
}

export function TokenValidated(tokenStr) {
	if(tokenStr) {
		let token; 
		try {
			token = jwtDecode(tokenStr);
		} catch (e) {
			if (e instanceof InvalidTokenError) {
				return null;
			}
			throw e;
		}
		if(token.scope && Date.now() < token.exp * 1000) {
			return token;
		}
	}
	return null;
}