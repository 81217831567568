// React will not add attributes to the dom if the attribute value is `undefined`
// So, we return the truthy input value if the input is true, or `undefined` if the input is falsy.
export function presentIf(bool) {
	return bool || undefined;
}

export function valueIf(bool,value) {
	if(bool) {
		return value;
	} else {
		return undefined;
	}
}

export const plurals = {
	s: t=>t+"s",
	es: t=>t+"es",
	ies: t=>t.substring(0,t.length-1)+"ies",
	ves1: t=> t.substring(0,t.length-1)+"ves",
	ves2: t=> t.substring(0,t.length-2)+"ves",
};

export function plural(quantity,text,pluralize) {
	if (quantity === 1) {
		return text;
	}
	if (typeof pluralize == "string") {
		return pluralize;
	}
	pluralize ??= plurals.s;
	return pluralize(text);
}