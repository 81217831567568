import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import { IconButton, InputAdornment, TextField, Button, Typography, Box, MenuItem, Autocomplete, Divider, Grid, Switch, CircularProgress, Tooltip } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import UndoIcon from "@mui/icons-material/Undo";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EmailIcon from "@mui/icons-material/Email";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LaunchIcon from "@mui/icons-material/Launch";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { RadioButtonChecked } from "@mui/icons-material";

import PageHeader from "components/Common/PageHeader";
import useAdminTools from "adminapi";
import ChoiceDialog from "components/Common/ChoiceDialog";
import { sessionHasScope } from "session/scope";
import { dateFormat, daysToMs, hoursToMs, hyphenatedDate, isValidDate, localToUtc, utcToLocal } from "helperFuncs/dateFormat";
import { useAlertStack } from "components/Common/AlertStack";
import { downloadFile, generateCsv, getState, setState, moveToFront } from "helperFuncs/dataHelp";
import { presentIf } from "helperFuncs/jsxHelp";
import { SpeechServices, LicenseTypes, SpeechSteno} from "helperFuncs/enums";
import "./LicenseEditor.css";

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FETCH_LIVE_BALANCE_EVERY = 60000; //ms

const Adjustment = {
	EMPTY: "No Balance",
	NONE: "Not Adjusted",
	REMOVED: "Balance Removed",
};

const customerSearchRenderOption = (props, option) => {
	if (option === null) {
		return null;
	}
	return <Box component='li' {...props} key={option.customer_id}>
		<table>
			<tbody>
				<tr>
					<td>
						<div className="searchCustomerName">{option.name}</div>
					</td>
				</tr>
				<tr>
					<td>
						<div className="searchCustomerEmail">{option.email}</div>
					</td>
				</tr>
			</tbody>
		</table>
	</Box>;
};




const loadVersionAutofillList = () => {
	let versionStr = localStorage.getItem("license/history/version");
	if (!versionStr) {
		return [];
	} else {
		let ret = versionStr
			.split(";", 100)
			.map((str) => Number(str))
			.filter((x) => x) // recall that NaN and 0 are falsy values.
			.map((x) => x.toFixed(1));
		return ret;
	}
};

const addVersionToAutofillList = (version, setList) => {
	let v = Number(version);

	if (!v) {
		return;
	}

	v = v.toFixed(1);

	let list = loadVersionAutofillList();
	let i = list.indexOf(v);

	if (i !== -1) {
		list.splice(i, 1); //remove from list if already exists
	}
	list.unshift(v); //adding to front of list corresponds to the top of the dropdown

	setList?.(list);

	let str = list.join(";");

	localStorage.setItem("license/history/version", str);
};

const randomString = (length, chars) => {
	let result = "";
	for (let i = length; i > 0; --i)
		result += chars[Math.round(Math.random() * (chars.length - 1))];
	return result;
};

const adjustedBalances = (license, selectedService, mins) => {
	const prevServices = license.speechServices;
	for (let i in prevServices) {
		if (prevServices[i].service === selectedService) {

			if (prevServices[i].minute_balance === mins) {
				return prevServices;
			}

			let copy = [...prevServices];
			if (mins !== undefined) {
				copy[i] = { ...copy[i], minute_balance: mins };
			} else {
				copy.splice(i, 1);
			}
			return copy;
		}
	}
	if (mins !== undefined) {
		return [...prevServices, {
			service: selectedService,
			minute_balance: mins,
		}];
	} else {
		return prevServices;
	}
};


const getAdjustment = (initLicense,newLicense,service) => {
	const initService = initLicense.speechServices?.find(s => s.service === service);
	const newService = newLicense.speechServices?.find(s => s.service === service);
	if(!newService) {
		if(initService) {
			return Adjustment.REMOVED;
		} else {
			return Adjustment.EMPTY;
		}
	}
	const initBalance = initService?.minute_balance ?? 0;
	const newBalance = newService.minute_balance;
	const adj = newBalance - initBalance;
	if(adj === 0) {
		return Adjustment.NONE;
	} else if(adj > 0) {
		return `+${adj} minutes`;
	} else {
		return `−${Math.abs(adj)} minutes`;
	}
};

const getLiveBalanceText = async (api,license_id,service) => {
	const getLiveBalanceUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/license/${license_id}/speech/${service}`;
	try {
		const response = await api.get(getLiveBalanceUrl);
		const mins = response.data.data.minute_balance;
		let balText = mins+" min";
		if (mins >= 60) {
			balText += ` (${hrFormat(mins)})`;
		}
		return <span>Live balance:<br />{balText}</span>;
	} catch (error) {
		console.log(error);
		return `Could not get live balance: ${error}`;
	}
};

function LicenseEditor(/*{props}*/) {
	const api = useAdminTools();
	const params = useParams();

	const writeScope = sessionHasScope("write");

	//license state
	const [license, setLicense] = useState({
		licenseId: "",
		licenseNo: params.licenseNo,
		customer: null,
		licenseType: "",
		stenoSpeech: "",
		versionNo: "",
		licenseExpiry: null,
		supportExpiry: null,
		bridgeExpiry: null,
		enableOffline: true,
		isKeyless: false,
		isDeadkey: false,
		speechServices: [],
	});
	//initial license state, as undefined, to be set on successful fetch.
	//used to determine if form is dirty.
	const [initLicense, setInitLicense] = useState(undefined);

	//page state
	const [isCreate,] = useState(!params.licenseNo);

	const [title,] = useState(()=>{
		if(isCreate) {
			return "Create a new license";
		}
		if(writeScope) {
			return `Edit License: ${license.licenseNo}`;
		}
		return `License Details: ${license.licenseNo}`;
	});

	const [customerInput, setCustomerInput] = useState("");
	const [selectedService, setSelectedService] = useState(SpeechServices.GLOBAL);
	const [selectedBalance, setSelectedBalance] = useState("");
	const [liveBalanceText, setLiveBalanceText] = useState("Not fetched");
	const [searchedCustomers, setSearchedCustomers] = useState([license.customer]);
	const [AlertStack, newAlert] = useAlertStack();
	const [allowReassign, setAllowReassign] = useState(isCreate);
	const [waiting, setWaiting] = useState(null);
	const usageReportState = useState({});

	//loading state
	const [hasLoaded, setHasLoaded] = useState(isCreate);
	const [pageError, setPageError] = useState(null);

	//dialog state
	const [warnCloseWithoutSaving, setWarnCloseWithoutSaving] = useState(false);
	const [warnSetDeadkey, setWarnSetDeadkey] = useState(false);
	const [warnAdjustMinutes, setWarnAdjustMinutes] = useState(false);
	const [openUsageReport, setOpenUsageReport] = useState(false);
	const [minuteAdjustment, setMinuteAdjustment] = useState("");

	const [versionAutofillList, setVersionAutofillList] = useState(loadVersionAutofillList);

	useEffect(() => {
		if(license?.licenseId) {
			setLiveBalanceText("Loading...");
			getLiveBalanceText(api,license.licenseId,selectedService).then((text)=>{
				setLiveBalanceText(text);
			});
			const timer = setInterval(() => {
				getLiveBalanceText(api,license.licenseId,selectedService).then((text)=>{
					setLiveBalanceText(text);
				});
			}, FETCH_LIVE_BALANCE_EVERY);

			return () => clearInterval(timer);
		}
	}, [isCreate, license, license.licenseId, selectedService]);

	const isDirty = () => {
		let init, current;
		setInitLicense(lic => init = lic);
		setLicense(lic => current = lic);
		if (!init) {
			return true;
		}

		let keys = Object.keys(current);
		return !keys.every(key => current[key] === init[key]);
	};

	const setInitValues = () => {
		setLicense(license => {
			setInitLicense(license);
			return license;
		});
	};

	const [, setSearchController] = useState(null);
	const performSearch = useCallback(async (value) => {
		let controller = new AbortController();
		setSearchController((prevController) => {
			if (prevController !== null) {
				//If a request is already in progress, abort it and start a new one.
				prevController.abort();
			}
			return controller;
		});
		if (value) {
			const searchUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/customer/search?query=${value}`;
			api.get(searchUrl, {
				signal: controller.signal
			}).then(response => {
				if (response.status === 200) {
					let data = response.data.data;
					if (data.customers?.length) {
						let found = false;
						if (license.customer) {
							for (let i = 0; i < data.customers.length; i++) {
								const c = data.customers[i];
								if (c.customer_id === license.customer.customer_id) {
									Object.assign(license.customer, c);
									data.customers[i] = license.customer;
									found = true;
									break;
								}
							}
						}
						if (found) {
							setSearchedCustomers(data.customers);
						} else {
							setSearchedCustomers([license.customer, ...data.customers]);
						}
					} else {
						setSearchedCustomers([license.customer]);
					}
				} else {
					throw response;
				}
			}).catch(error => {
				//If a request is aborted, it returns with the message 'canceled'
				//Since this is expected behavior, do not treat it like an error.
				if (error.message === "canceled") {
					return;
				}
				console.log("error: ", error);
			}).finally(() => {
				setSearchController(null);
			});
		} else {
			setSearchedCustomers([license.customer]);
			setSearchController(null);
		}
	}, [api, license.customer]);

	const handleReassign = () => {
		setAllowReassign(true);
	};

	const handleLicenseType = (event) => {
		let val = event.target.value;
		setLicense({
			...license,
			licenseType: val
		});
	};

	const handleIsKeyless = (event) => {
		let val = event.target.checked;
		setLicense({
			...license,
			isKeyless: val
		});
	};

	const handleSendEmail = () => {
		if (isDirty()) {
			newAlert("info", "Please save your changes first.", "Unsaved changes");
		} else {
			emailLicense();
		}
	};

	const handleSelectedService = (event) => {
		const newService = event.target.value;
		setSelectedService(newService);
		const serviceFound = license.speechServices.find(s => s.service === newService);
		if(isCreate) {
			setSelectedBalance(serviceFound?.minute_balance ?? "");
		} else {
			setSelectedBalance(getAdjustment(initLicense,license,newService));
		}
	};

	const handleStenoSpeech = (event) => {
		let val = event.target.value;
		setLicense({
			...license,
			stenoSpeech: val,
		});
	};

	const handleChangeOffline = (event) => {
		setLicense({
			...license,
			enableOffline: event.target.checked,
		});
	};

	const handleCustomer = (event, newValue) => {
		setLicense({
			...license,
			customer: newValue,
		});
	};

	const handleCustomerInput = (event) => {
		const searchString = event?.target.value ?? "";
		setCustomerInput(searchString);
		performSearch(searchString);
	};

	const handleVersionNoTextBox = (event) => {
		let val = event.target.value;
		if (event.target.validity.valid) {
			setLicense({
				...license,
				versionNo: val,
			});
		}
	};

	const handleVersionNoAutoComplete = (event, val) => {
		if (/^\d+\.\d$/.test(val)) {
			setLicense({
				...license,
				versionNo: val,
			});
		}
	};

	const handleLicenseNo = (event) => {
		setLicense({
			...license,
			licenseNo: event.target.value.toUpperCase(),
		});
	};

	const handleMinuteBalance = (event) => {
		if (!event.target.validity.valid) {
			return;
		}

		let mins = event.target.value;
		setSelectedBalance(mins);
		if(mins === "") {
			mins = undefined;
		} else {
			mins = +mins;
		}
		const newServices = adjustedBalances(license, selectedService, mins);

		setLicense({
			...license,
			speechServices: newServices,
		});
	};

	const handleDeadkey = (/*event*/) => {
		if (license.isDeadkey) {
			setLicense({
				...license,
				isDeadkey: false,
			});
		} else {
			setWarnSetDeadkey(true);
		}
	};

	const handleAdjustMinutes = (/*event*/) => {
		setWarnAdjustMinutes(true);
	};

	const handleLicenseExpiry = (value) => {
		if (isValidDate(value) || value == null) {
			setLicense({
				...license,
				licenseExpiry: localToUtc(value),
			});
		}
	};
	const handleSupportExpiry = (value) => {
		if (isValidDate(value) || value == null) {
			setLicense({
				...license,
				supportExpiry: localToUtc(value) ?? null,
			});
		}
	};
	const handleBridgeExpiry = (value) => {
		if (isValidDate(value) || value == null) {
			setLicense({
				...license,
				bridgeExpiry: localToUtc(value) ?? null,
			});
		}
	};

	const handleAdjustMinutesDialog = (event) => {
		if (!event.target.validity.valid) {
			return;
		}
		setMinuteAdjustment(event.target.value);
	};

	const licenseExists = async (tempLicNo) => {

		const getLicNoUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/license/no/${tempLicNo}`;

		try {
			await api.get(getLicNoUrl);
			return true;
		} catch (error) {
			if (error.response.status === 404) {
				return false;
			} else {
				throw error;
			}
		}
	};

	const LicenseNum = () => {
		return randomString(8, "ACEGHIJKLOQRUWXY");
	};

	const setLicenseNoAutoGenerate = async () => {
		if (waiting) {
			return;
		} else {
			setWaiting("generateKey");
		}
		try {
			let tempLicNo = "";

			do {
				tempLicNo = LicenseNum();
			} while (await licenseExists(tempLicNo));

			setLicense({
				...license,
				licenseNo: tempLicNo,
				isKeyless: true,
			});

		} catch (error) {
			newAlert("error", <>Could generate unique license number, received error:<br />{error?.toString()}</>, "Error");
		} finally {
			setWaiting(null);
		}
	};

	const submitRequest = () => {
		const licPostUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/license/`;

		//Mapping of properties to api params that can be passed as-is.
		//Not included in object constructor since we must first check if
		//the values in question were actually changed on the form.
		const apiMap = new Map();
		apiMap.set("licenseType", "license_type");
		apiMap.set("stenoSpeech", "steno_speech");
		apiMap.set("licenseExpiry", "license_expire_date");
		apiMap.set("supportExpiry", "support_expire_date");
		apiMap.set("bridgeExpiry", "bridge_expire_date");
		apiMap.set("speechServices", "speech_services");
		apiMap.set("enableOffline", "offline_capable");
		apiMap.set("isKeyless", "keyless");
		apiMap.set("isDeadkey", "deadkey");

		//for PATCH license: only include values that are to be modified in web request.
		//Meaning: if you didn't update the version number, that item shouldn't be sent to the server.
		const body = {
			license_no: license.licenseNo,
		};

		Object.keys(license).forEach((key) => {
			if (license[key] !== initLicense?.[key]) {
				const val = license[key];
				if (val !== undefined) {
					//Check for certain properties that need formatting/cannot be passed directly.
					switch (key) {
					case "licenseId":
					case "licenseNo":
						return; //primary key must never change; passing it in will be ignored.
					case "versionNo":
						return body.version = Number(val);
					case "customer": {
						const initCustomer = initLicense?.[key];
						if (val?.customer_id !== initCustomer?.customer_id) {
							if(val?.customer_id) {
								body.customer = {
									customer_id: val.customer_id,
									account_id: val.account_id,
								};
							}
						}
						return;
					}
					default: {
						const bodyProp = apiMap.get(key);
						if (!bodyProp) {
							console.log("bad key", key);
						}
						return body[bodyProp] = val ?? "";
					}
					}
				}
			}
		});

		setWaiting("submit");

		let requestChain;
		if (isCreate) {
			requestChain = api.post(licPostUrl, body).then(response => {
				if (response.status === 201) {
					if(license.customer?.email && license.isKeyless) {
						newAlert("success", "Successfully created license. Sending email...", "Created");
						emailLicense();
					} else {
						newAlert("success", "Successfully created license.", "Created");
						navigate(`/license/${license.licenseNo}`);
					}
					return response;
				} else {
					throw response;
				}
			});
		} else {
			requestChain = api.patch(licPostUrl + license.licenseId, body).then(response => {
				if (response.status === 200) {
					newAlert("success", "Successfully updated license details.", "Updated");
					setAllowReassign(false);
					return response;
				} else {
					throw response;
				}
			});
		}
		requestChain.then(response => {
			setLicense({...license});
			addVersionToAutofillList(license.versionNo, setVersionAutofillList);
			setInitValues();
			setState(usageReportState,{}); //clear cached usage report
			let selected = license.speechServices.find((s)=>s.service == selectedService);
			if(selected) {
				if(isCreate) {
					setSelectedBalance(selected.minute_balance);
				} else {
					setSelectedBalance(selected ? Adjustment.NONE : Adjustment.EMPTY);
				}
			}
			return response;
		}).catch(error => {
			newAlert("error", <>Could not save license details, received error:<br />{
				error?.response?.data?.data?.message ?? error?.message
			}</>, "Error");
			console.log("save error:", error?.response?.data ?? error);
		}).finally(() => {
			setWaiting(null);
		});
	};

	//Fetch license data
	useEffect(() => {
		if (!isCreate) {
			const getLicNoUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/license/no/`;
			api.get(getLicNoUrl + params.licenseNo).then((response) => {
				if (response.status === 200) {
					let fetched = response.data.data.license;
					let cust;
					if (fetched.customer) {
						cust = {
							customer_id: fetched.customer.customer_id,
							account_id: fetched.customer.account_id,
							name: fetched.customer.name,
							email: fetched.customer.email ?? "",
						};
					} else {
						cust = null;
					}

					let licexp, supexp, bdgexp;

					if (fetched.license_expire_date) {
						licexp = new Date(fetched.license_expire_date);
						if (!isValidDate(licexp)) {
							licexp = null;
						}
					}

					if (fetched.support_expire_date) {
						supexp = new Date(fetched.support_expire_date);
						if (!isValidDate(supexp)) {
							supexp = null;
						}
					}

					if (fetched.bridge_expire_date) {
						bdgexp = new Date(fetched.bridge_expire_date);
						if (!isValidDate(bdgexp)) {
							bdgexp = null;
						}
					}


					let spSv = [];
					if(fetched.speech_services?.length > 0) {
						let autoselect = fetched.speech_services.find((s)=>s.service == "global") ?? fetched.speech_services[0];
						spSv = fetched.speech_services;
						setSelectedService(autoselect.service);
						if(isCreate) {
							setSelectedBalance(autoselect.minute_balance);
						} else {
							setSelectedBalance(autoselect ? Adjustment.NONE : Adjustment.EMPTY);
						}
					} else {
						setSelectedBalance(Adjustment.EMPTY);
					}

					setLicense({
						licenseId: fetched.license_id,
						licenseNo: fetched.license_no,
						licenseType: fetched.license_type,
						stenoSpeech: fetched.steno_speech,
						versionNo: fetched.version.toFixed(1),
						customer: cust,
						licenseExpiry: licexp,
						supportExpiry: supexp,
						bridgeExpiry: bdgexp,
						enableOffline: fetched.offline_capable,
						isDeadkey: fetched.deadkey,
						isKeyless: fetched.keyless,
						speechServices: spSv,
					});

					setInitValues();
					setHasLoaded(true);
				}
			}).catch(error => {
				console.log(error);
				setHasLoaded(true);
				setPageError(error);
			});
		}
	}, [params.licenseNo, isCreate, api]);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (waiting)
			return;

		let validationErrors = [];

		if (!license.licenseNo) {
			validationErrors.push(<li key="licenseReq">License Number is required</li>);
		} else if (license.licenseNo !== initLicense?.licenseNo) {
			if (license.licenseNo.length < 8 || license.licenseNo.length > 20) {
				validationErrors.push(<li key="licenseSize">License Number must be between 8-20 characters long</li>);
			} else if (license.licenseNo.match(/(?=.*[!BDMNSFPTVZ$^&*])/)) {
				validationErrors.push(<li key="licenseVal">License Number must not contain any of:
					<br />B, D, F, M, N, P, S, T, V, Z</li>);
			}

			if (license.isKeyless) {
				if (license.licenseNo.match(/\d/)) {
					validationErrors.push(<li key="licenseReq">Keyless licenses must not contain digits</li>);
				}
			} else {
				if (license.licenseNo.match(/[A-Za-z]/)) {
					validationErrors.push(<li key="licenseReq">Hardware licenses must not contain letters</li>);
				}
			}
		}

		if (license.licenseType !== initLicense?.LicenseType) {
			if (license.licenseType === LicenseTypes.NONE) {
				validationErrors.push(<li key="typeReq">License Type is required</li>);
			}
		}

		if (license.versionNo !== initLicense?.VersionNo) {
			if (license.versionNo === "") {
				validationErrors.push(<li key="versionReq">Version is required</li>);
			} else if (Number(license.versionNo) <= 0) {
				validationErrors.push(<li key="versionReq">Version must be greater than 0.0</li>);
			}
		}

		if (license.supportExpiry !== initLicense?.SupportExpiry) {
			if (license.supportExpiry === null) {
				validationErrors.push(<li key="supExpReq">Support Expiration Date is required</li>);
			} else if (!isValidDate(license.supportExpiry)) {
				validationErrors.push(<li key="supExpVal">Support Expiration Date is not a valid date</li>);
			}
		}

		if (license.licenseExpiry !== initLicense?.LicenseExpiry) {
			if (license.licenseExpiry !== null && !isValidDate(license.licenseExpiry)) {
				validationErrors.push(<li key="licExpVal">License Expiration Date is not a valid date</li>);
			}
		}

		if (license.bridgeExpiry !== initLicense?.BridgeExpiry) {
			if (license.bridgeExpiry !== null && !isValidDate(license.bridgeExpiry)) {
				validationErrors.push(<li key="bdgExpVal">Bridge Expiration Date is not a valid date</li>);
			}
		}

		if (license.stenoSpeech !== initLicense?.StenoSpeech) {
			if (license.stenoSpeech === "") {
				validationErrors.push(<li key="stSpReq">Steno/Speech is required</li>);
			}
		}

		if (license.licenseType?.includes("+Bridge")) {
			if (license.bridgeExpiry === null) {
				validationErrors.push(<li key="bdgExpVal">Bridge Expiration Date must be set for Bridge Licenses</li>);
			}
		}

		if (validationErrors.length !== 0) {
			newAlert("error", <ul>{validationErrors}</ul>, "Invalid Fields");
		} else if (isDirty()) {
			submitRequest();
		} else {
			newAlert("info", "No changes were made");
		}

	};

	const emailUrl = `${process.env.REACT_APP_EMAIL_URL}/api/v1/email/license` +
    `?license_no=${license.licenseNo}&is_keyless=${license.isKeyless}`;

	const emailLicense = () => {
		if (!license.customer?.email || !license.customer?.email.match(emailPattern)) {
			newAlert("error", <><b>Customer email</b> is not a valid email address.</>);
			return;
		}
		if (waiting) {
			throw new Error(`waiting on:${waiting}`);
		} else {
			setWaiting("email");
		}

		api.post(emailUrl, [
			license.customer.email
		], {
			headers: {
				"x-api-key": `APIKEY ${process.env.REACT_APP_EMAIL_APIKEY}`
			}
		}).then(response => {
			if (response.status === 200) {
				newAlert("success", `License sent to ${response.data.data.sent_to.join()}`, "Email sent");
			} else {
				throw response;
			}
		}).catch(error => {
			newAlert("error", <>Email failed to send:<br />{error?.toString()}</>);
		}).finally(() => {
			setWaiting(null);
			if (isCreate) {
				navigate(`/license/${license.licenseNo}`);
			}
		});
	};

	const navigate = useNavigate();
	const location = useLocation();


	const handleGoBack = useCallback(() => {
		if (isDirty()) {
			setWarnCloseWithoutSaving(true);
		} else {
			if(location.key !== "default") {
				navigate(-1);
			} else {
				navigate("/dashboard");
			}
		}
	},[location]);

	const handleCloseWithoutSave = (confirmed) => {
		if (confirmed) {
			if(location.key !== "default") {
				navigate(-1);
			} else {
				navigate("/dashboard");
			}
		} else {
			setWarnCloseWithoutSaving(false);
		}
	};

	const handleConfirmDeadkey = (confirmed) => {
		if (confirmed) {
			setLicense({
				...license,
				isDeadkey: true,
			});
		}
		setWarnSetDeadkey(false);
	};

	const handleConfirmAdjustMinutes = useCallback((confirmed) => {
		if (confirmed) {
			const oldService = license.speechServices.find(s => s.service === selectedService);
			const newValue = (oldService?.minute_balance ?? 0) + Number(minuteAdjustment);
			if(!isFinite(newValue)) {
				newAlert("error","Please enter a valid number");
				return;
			}
			const newServices = adjustedBalances(license, selectedService, newValue);
			const updatedLicense = {
				...license,
				speechServices: newServices,
			};
			setSelectedBalance(getAdjustment(initLicense,updatedLicense,selectedService));
			setLicense(updatedLicense);
		}
		setMinuteAdjustment("");
		setWarnAdjustMinutes(false);
	},[license,minuteAdjustment,selectedBalance,selectedService]);

	const handleMinuteReport = useCallback(() => {
		setOpenUsageReport(true);
	});

	const handleMinuteReportDialog = useCallback((download) => {
		if (download) {
			const report = getState(usageReportState);
			if(report.csvData?.length > 0) {
				const blob = new Blob([generateCsv(report)], {type: "text/csv"});
				const filename = `${license.licenseNo}-usage.csv`;
				downloadFile(blob, filename);
			}
		} else {
			setOpenUsageReport(false);
		}
	},[license,minuteAdjustment,selectedBalance,selectedService]);

	const customerSearchRenderInput = useCallback((params) => { 
		const email = license.customer?.email;
		let contLink = license.customer?.customer_id;
		let acctLink = license.customer?.account_id;
		contLink &&= <Tooltip title="Go to Customer's Contact in Zoho">
			<a href={`${process.env.REACT_APP_ZOHOCRM_URL}/tab/Contacts/${contLink}`}
				target="_blank" rel="noopener noreferrer"
			>
				<LaunchIcon />
			</a>
		</Tooltip>;
		acctLink &&= <Tooltip title="Go to Customer's Account in Zoho">
			<a href={`${process.env.REACT_APP_ZOHOCRM_URL}/tab/Accounts/${acctLink}`}
				target="_blank" rel="noopener noreferrer"
			>
				<AccountBalanceIcon />
			</a>
		</Tooltip>;
		return <>
			<TextField
				{...params}
				label="Customer"
				value={customerInput}
				inputProps={{
					...params.inputProps,
					autoComplete: "new-password", // disable autocomplete and autofill
				}}
			/>
			<div className="customerInfo">
				{email}
				{contLink}
				{acctLink}
			</div>
		</>;
	}
	, [license.customer, customerInput]);

	let displayMessage = null;
	if (!hasLoaded) {
		displayMessage = "Loading...";
	} else if (pageError) {
		displayMessage = `Error getting license data: ${pageError.toString()}`;
	}

	if (displayMessage) {
		return <>
			<PageHeader title={title}/>
			<div className="centeredError" >
				{displayMessage}
			</div>
		</>;
	}

	let reassignLicenseButton, sendEmailButton, submitButton;
	if (writeScope) {
		reassignLicenseButton = <Button
			fullWidth
			variant='outlined'
			className="editorButton"
			onClick={handleReassign}
		>
			<UndoIcon />Reassign
		</Button>;

		let sendEmailButtonContent;
		if (waiting === "email") {
			sendEmailButtonContent = <CircularProgress disableShrink size="1.5rem" />;
		} else {
			sendEmailButtonContent = <><EmailIcon />Email .lic File</>;
		}
		sendEmailButton = <Button
			fullWidth
			variant='outlined'
			className="editorButton"
			onClick={handleSendEmail}
			disabled={Boolean(waiting)}
		>
			{sendEmailButtonContent}
		</Button>;

		let submitButtonContent;
		if (waiting === "submit") {
			submitButtonContent = <CircularProgress disableShrink size="1.5rem" />;
		} else if (isCreate) {
			submitButtonContent = <><DoneAllIcon />Save</>;
		} else {
			submitButtonContent = <><AutorenewIcon />Update</>;
		}

		submitButton = <Button
			fullWidth
			variant='outlined'
			className="editorButton"
			type='submit'
			disabled={Boolean(waiting)}
		>
			{submitButtonContent}
		</Button>;
	}

	let autoGenerateButtonContent;
	if (waiting === "generateKey") {
		autoGenerateButtonContent = <CircularProgress size={20} />;
	} else {
		autoGenerateButtonContent = <RefreshSharpIcon />;
	}

	let autoGenerateButton, editModeButtons, adornments;
	if (isCreate) {
		adornments = {};
		editModeButtons = [];
		autoGenerateButton = <Tooltip title="Auto generate">
			<Button
				className="autoGenerateButton"
				variant='outlined'
				disabled={waiting !== null}
				onClick={setLicenseNoAutoGenerate}
			>
				{autoGenerateButtonContent}
			</Button>
		</Tooltip>;
	} else {
		const canReport = sessionHasScope("report");
		let Icon, onClick;
		if (canReport) {
			Icon = SummarizeIcon;
			onClick = handleMinuteReport;
		} else {
			Icon = RadioButtonChecked;
		}
		adornments = {
			startAdornment: <InputAdornment position="start">
				<Tooltip title={liveBalanceText} placement="top">
					<IconButton
						onClick={onClick}
					>
						<span className="minuteBalanceLiveIcon" data-report={presentIf(canReport)}>
							<Icon/>
						</span>
					</IconButton>
				</Tooltip>
			</InputAdornment>
		};

		if(writeScope) {
			adornments.endAdornment = <InputAdornment position="end">
				<IconButton
					onClick={handleAdjustMinutes}
				>
					<AddBoxIcon className="minuteBalanceAddIcon" />
				</IconButton>
			</InputAdornment>;
		}
		autoGenerateButton = null;
		const activityCheckerButton = <Button
			fullWidth
			variant='outlined'
			className="editorButton"
			component={Link}
			to={`/license/${license.licenseNo}/activity`}
		>
			<><ShowChartIcon />Activity Checker</>
		</Button>;
		editModeButtons = [
			reassignLicenseButton,
			sendEmailButton,
			activityCheckerButton,
		];
	}

	return <>
		<PageHeader title={title} onBack={handleGoBack}/>
		<div className="licenseEditor" data-create={presentIf(isCreate)}>
			<form autoComplete='off' onSubmit={handleSubmit}>
				<br />
				<Grid container spacing={1} rowSpacing={1}>
					<Grid item md={4} sm={6} xs={12}>
						<Autocomplete
							label="Customer"
							variant='outlined'
							size='small'
							freeSolo
							fullWidth
							options={searchedCustomers ?? []}
							getOptionLabel={(customer) => customer?.name ?? ""}
							filterOptions={(options,) => options}
							disabled={!allowReassign}
							value={license.customer}
							onChange={handleCustomer}
							onInputChange={handleCustomerInput}
							renderOption={customerSearchRenderOption}
							renderInput={customerSearchRenderInput}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						{autoGenerateButton}
						<div className="licenseNoWrapper">
							<TextField
								label="License Number"
								variant='outlined'
								size='small'
								disabled={Boolean(waiting) || !(writeScope && isCreate)}
								value={license.licenseNo ?? ""}
								onChange={handleLicenseNo}
								InputProps={{
									endAdornment:
										initLicense?.licenseId && <Tooltip title="Go to License in Zoho">
											<a href={`${process.env.REACT_APP_ZOHOCRM_URL}/tab/CustomModule3/${initLicense.licenseId}`}
												target="_blank" rel="noopener noreferrer"
											>
												<LaunchIcon />
											</a>
										</Tooltip>
								}}
								inputProps={{
									value: license.licenseNo ?? "",
								}}
							/>
						</div>
					</Grid>
					<Grid item container md={4} sm={6} xs={12}>
						{[...editModeButtons,submitButton].map((button,i)=>
							<Grid item key={i} sm={6} xs={12}>
								{button}
							</Grid>
						)}
					</Grid>
				</Grid>
				<Divider />
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Typography variant='h5'>
						License Details
					</Typography>
					<Grid container spacing={1} rowSpacing={1}>
						<Grid item md={4} sm={6} xs={12}>
							<TextField select
								fullWidth
								disabled={!writeScope}
								value={license.licenseType}
								label="License Type"
								size='small'
								onChange={handleLicenseType}
							>
								<MenuItem value={LicenseTypes.NONE}>&nbsp;</MenuItem>
								<MenuItem value={LicenseTypes.STUDENT}>Student</MenuItem>
								<MenuItem value={LicenseTypes.EDIT}>Edit</MenuItem>
								<MenuItem value={LicenseTypes.EDIT_BRIDGE}>Edit + Bridge</MenuItem>
								<MenuItem value={LicenseTypes.TRAN}>Tran</MenuItem>
								<MenuItem value={LicenseTypes.TRAN_BRIDGE}>Tran + Bridge</MenuItem>
								<MenuItem value={LicenseTypes.ACCUCAP}>AccuCap</MenuItem>
								<MenuItem value={LicenseTypes.ACCUCAP_BRIDGE}>AccuCap + Bridge</MenuItem>
								<MenuItem value={LicenseTypes.NOECLIPSE_BRIDGE}>Non-Eclipse + Bridge</MenuItem>
											
								<MenuItem value={LicenseTypes.RSR}>RSR</MenuItem>
								<MenuItem value={LicenseTypes.RSR_BRIDGE}>RSR + Bridge</MenuItem>
								<MenuItem value={LicenseTypes.RSR_TRAN}>RSR + Tran</MenuItem>
								<MenuItem value={LicenseTypes.RSR_TRAN_BRIDGE}>RSR + Tran + Bridge</MenuItem>
								<MenuItem value={LicenseTypes.RSR_ACCUCAP}>RSR + AccuCap</MenuItem>
								<MenuItem value={LicenseTypes.RSR_ACCUCAP_BRIDGE}>RSR + AccuCap + Bridge</MenuItem>
							</TextField>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<DatePicker
								label="Support Expiration Date"
								onChange={handleSupportExpiry}
								disabled={!writeScope}
								value={utcToLocal(license.supportExpiry) ?? null}
								slotProps={{ 
									textField:{
										fullWidth:true,
										disabled:!writeScope,
										size:"small",
									}
								}}
							/>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<TextField select
								fullWidth
								value={selectedService}
								label="Service"
								size='small'
								onChange={handleSelectedService}
							>
								<MenuItem value={SpeechServices.GLOBAL}>All Services</MenuItem>
								<MenuItem value={SpeechServices.GOOGLE_SPEECH}>Google Speech</MenuItem>
								<MenuItem value={SpeechServices.SPEECHMATICS}>Speechmatics</MenuItem>
								<MenuItem value={SpeechServices.WATSON}>Watson</MenuItem>
								<MenuItem value={SpeechServices.AZURE}>Azure</MenuItem>
								<MenuItem value={SpeechServices.KENSHO}>Kensho</MenuItem>
							</TextField>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<TextField select
								fullWidth
								value={license.stenoSpeech}
								disabled={!writeScope}
								size='small'
								label="Steno/Speech"
								onChange={handleStenoSpeech}
							>
								<MenuItem value={SpeechSteno.NONE}>&nbsp;</MenuItem>
								<MenuItem value={SpeechSteno.SPEECH}>Speech</MenuItem>
								<MenuItem value={SpeechSteno.STENO}>Steno</MenuItem>
								<MenuItem value={SpeechSteno.BOTH}>Both</MenuItem>
							</TextField>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<DatePicker
								label="License Expiration Date"
								onChange={handleLicenseExpiry}
								disabled={!writeScope}
								value={utcToLocal(license.licenseExpiry) ?? null}
								slotProps={{ 
									textField:{
										fullWidth:true,
										disabled:!writeScope,
										size:"small",
									}
								}}
							/>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<TextField
								fullWidth
								value={selectedBalance}
								size='small'
								className="minuteBalance"
								disabled={!(writeScope && isCreate)}
								label="Minute Balance"
								variant='outlined'
								InputProps={adornments}
								inputProps={{
									pattern: "[+\\-]?\\d{0,6}",
									value: selectedBalance,
								}}
								onChange={handleMinuteBalance}
							/>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<Autocomplete
								freeSolo
								disabled={!writeScope}
								options={versionAutofillList ?? []}
								inputValue={license.versionNo}
								value={license.versionNo}
								onChange={handleVersionNoAutoComplete}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Version"
										variant='outlined'
										size='small'
										//
										inputProps={{
											...params.inputProps,
											pattern: "(^$|\\d{1,2}(\\.\\d?)?)",
											value: license.versionNo,
										}}
										onChange={handleVersionNoTextBox}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Divider />
					<Grid container>
						<Grid item lg={3} md={5} sm={7} xs={8}>
							<Typography variant='h5'>
								Additional functionality
							</Typography>
							<DatePicker
								label="Bridge Expiration Date"
								onChange={handleBridgeExpiry}
								disabled={!writeScope}
								value={utcToLocal(license.bridgeExpiry) ?? null}
								slotProps={{ 
									textField:{
										disabled:!writeScope,
										size:"small",
									}
								}}
							/>
							<Typography>
								<Switch
									checked={license.enableOffline}
									disabled={!writeScope}
									onChange={handleChangeOffline}
								/>
									Offline Capable
							</Typography>
							<Typography>
								<Switch
									disabled={!writeScope}
									checked={license.isKeyless}
									onChange={handleIsKeyless}
								/>
									Is Keyless
							</Typography>
						</Grid>
					</Grid>
					<Divider />
					<Typography variant='h5'>
						Deadkey Status
					</Typography>
					<Typography>
						<Switch
							disabled={!writeScope}
							checked={license.isDeadkey}
							onChange={handleDeadkey}
							color='warning'
						/>
							Deadkey
					</Typography>
				</LocalizationProvider>
			</form>
		</div>

		<ChoiceDialog
			dialogOpen={warnCloseWithoutSaving}
			title="Unsaved changes"
			confirmElement={<>Leave</>}
			cancelElement={<>Stay</>}
			onDialogClose={handleCloseWithoutSave}
		>
			Leave without saving?
		</ChoiceDialog>

		<ChoiceDialog
			dialogOpen={warnSetDeadkey}
			title="Assign Deadkey"
			confirmElement={<>Confirm</>}
			cancelElement={<>Cancel</>}
			onDialogClose={handleConfirmDeadkey}
		>
			This will set the license as a deadkey.
		</ChoiceDialog>

		<ChoiceDialog
			dialogOpen={warnAdjustMinutes}
			title={"Adjust speech balance"}
			confirmElement={<>Confirm</>}
			cancelElement={<>Cancel</>}
			onDialogClose={handleConfirmAdjustMinutes}
		>
			Enter the number of minutes to add or remove:
			<br /><br />
			<TextField
				value={minuteAdjustment}
				size='small'
				label="Amount to Add or Remove"
				variant='outlined'
				inputProps={{
					pattern: "[+\\-]?\\d{0,6}",
					value: minuteAdjustment,
				}}
				onChange={handleAdjustMinutesDialog}
			/>
		</ChoiceDialog>

		<ChoiceDialog
			dialogOpen={openUsageReport}
			title={"Usage Report for "+license.licenseNo}
			confirmElement={<>Download</>}
			cancelElement={<>Back</>}
			onDialogClose={handleMinuteReportDialog}
		>
			<UsageReport
				reportState={usageReportState}
				licenseId={license.licenseId}
			/>
		</ChoiceDialog>

		<AlertStack />
	</>;
}

const UsageReport = ({reportState,licenseId}) => {
	const api = useAdminTools();
	const [report, ] = reportState;
	const forceUpdate = (([,s])=>()=>s(g=>!g))(useState()); //evaluates to a function that toggles a react state `()=>s(g=>!g)` when called.

	report.days ??= 30;
	report.unit ??= "Minute";
	const endDate = new Date(Date.now()+daysToMs(1));
	const startDate = new Date(Date.now()-daysToMs(1+report.days));

	useEffect(() => {
		if(!report.ready) {
			
			const getReportUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/reports/usage?license_id=${licenseId
			}&start_date=${hyphenatedDate(startDate)}&end_date=${hyphenatedDate(endDate)}`;
			
			api.get(getReportUrl).then((data)=>{
				report.error = undefined;
				report.data = data.data;
				forceUpdate();
			}).catch((error)=>{
				console.log(error);
				report.error = error;
			});
		}
	},[report.ready,report.days]);

	useEffect(()=>{
		if(report?.data) {
			reportGen(report,licenseId);
			report.ready = true;
			forceUpdate();
		}
	},[report,report.data,report.unit]);

	const handleUnitChange = useCallback((event)=>{
		report.unit = event.target.value;
		forceUpdate();
	},[report]);

	const handleDaysChange = useCallback((event)=>{
		report.days = event.target.value;
		report.ready = false;
		forceUpdate();
	},[report]);

	if (report.error) {
		return JSON.stringify(report.error);
	} else if (!report.ready) {
		return <CircularProgress />;
	}

	const rData = report.data.report?.[licenseId];
	let content;
	if (!rData || (rData.usage?.length ?? 0) + (rData.adjustments?.length ?? 0) === 0) {
		content = "No usage data";
	} else {
		content = <table className="reportTable">
			<thead>
				<tr>
					{
						report.csvHeaders.map((str)=><th key={str}>{str.replaceAll(" ","\u00A0")}</th>)
					}
				</tr>
			</thead>
			<tbody>
				{
					report.csvData.map((row,i)=><tr key={i}>
						<th>{row[0]}</th>
						{
							row.filter((_,i)=>i > 0).map((val,i)=><td key={i}>
								{val ?? ""}
							</td>)
						}
					</tr>)
				}
			</tbody>
		</table>;
	}

	return <>
		<Grid container spacing={0.25}>
			<Grid item md={4}>
				<TextField select
					size='small'
					label="Days"
					onChange={handleDaysChange}
					value={report.days}
				>
					<MenuItem value={30}>30</MenuItem>
					<MenuItem value={60}>60</MenuItem>
					<MenuItem value={90}>90</MenuItem>
					<MenuItem value={180}>180</MenuItem>
					<MenuItem value={365}>365</MenuItem>
				</TextField>
			</Grid>
			<Grid item md={4}>
				<TextField select
					size='small'
					label="Unit"
					onChange={handleUnitChange}
					value={report.unit}
				>
					<MenuItem value="Minute">Minute</MenuItem>
					<MenuItem value="Hour">Hour</MenuItem>
				</TextField>
			</Grid>
		</Grid>
		<br />
		{content}
	</>;
};

const hrFormat = (minutes) => {
	let ret = "";
	if(minutes === undefined) {
		return ret;
	}
	if(minutes < 0) {
		ret += "-";
		minutes = -minutes;
	}
	ret += Math.trunc(minutes/60);
	ret += ":";
	ret += (minutes%60).toString().padStart(2,"0");
	return ret;
};

const balanceFormats = new Map([
	["Minute", minutes => minutes?.toString() ?? ""],
	["Hour", hrFormat],
]);

const reportGen = (report,licenseId) => {
	
	const rData = report.data.report?.[licenseId];
	if (!rData || (rData.usage?.length ?? 0) + (rData.adjustments?.length ?? 0) === 0) {
		return;
	}
	const tData = new Map(); //map[service]map[day](usageSum,adjustmentSum)
	
	for(let u of rData.usage ?? []) {
		if(!tData.has(u.service)) {
			tData.set(u.service,new Map());
		}
		const day = dateFormat(new Date(hoursToMs(u.unix_hour)));
		let uData = tData.get(u.service).get(day);
		if(uData) {
			uData.usageSum += u.minutes_used;
		} else {
			tData.get(u.service).set(day,{usageSum:u.minutes_used});
		}
	}
	for(let a of rData.adjustments ?? []) {
		if(!tData.has(a.service)) {
			tData.set(a.service,new Map());
		}
		const day = dateFormat(new Date(a.adjusted_at));
		let uData = tData.get(a.service).get(day);
		if(uData) {
			uData.adjSum = (uData.adjSum ?? 0) + a.adjustment;
		} else {
			tData.get(a.service).set(day,{adjSum:a.adjustment});
		}
	}

	const services = moveToFront("global",Array.from(tData.keys()));
	report.csvHeaders = services.flatMap((str)=>{
		let service = str.replaceAll("_"," ").replace(/\w\S*/g,
			(word)=>word.charAt(0).toUpperCase()+word.substring(1)
		);
		return [service+" used", service+" adj."];
	});
	report.csvData = [];
	const format = balanceFormats.get(report.unit);

	for(let i = 0;i < report.days+1;i++) {
		let date = new Date();
		date.setDate(date.getDate()-i);
		date = dateFormat(date);
		//
		let doPush = false;
		const row = [date];
		for(let i = 0;i < services.length;i++) {
			const service = services[i];
			const uData = tData.get(service).get(date);
			let u = uData?.usageSum;
			let a = uData?.adjSum;
			if(a >= 0) {
				a = "+"+format(a);
			} else {
				a = format(a);
			}
			row[i*2+1] = format(u);
			row[i*2+2] = a;
			if(a || u) {
				doPush = true;
			}
		}
		if(doPush) {
			report.csvData.push(row);
		}
	}
	report.csvHeaders.unshift("Date");
	const remove = [];
	for(let j = 1;j < report.csvHeaders.length;j++) {
		let rm = true;
		for(let i = 0;i < report.csvData.length;i++) {
			if(report.csvData[i][j]) {
				rm = false;
				break;
			}
		}
		if(rm) {
			remove.push(j);
		}
	}
	remove.reverse();
	for(let rm of remove) {
		report.csvHeaders.splice(rm,1);
		for(let row of report.csvData) {
			row.splice(rm,1);
		}
	}
};

export default LicenseEditor;