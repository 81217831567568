import React, { useCallback, useState, useEffect } from "react";

import { MenuItem, Select, TextField, InputAdornment, Button, Checkbox, Tooltip, Stack, Tab, Tabs, IconButton, CircularProgress } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";

import { useForceUpdate } from "customHooks";
import useAdminTools from "adminapi";
import PageHeader from "components/Common/PageHeader";
import ChoiceDialog from "components/Common/ChoiceDialog";
import { useAlertStack } from "components/Common/AlertStack";
import { moveToFront } from "helperFuncs/dataHelp";
import "./BulkUpdate.css";
import { presentIf } from "helperFuncs/jsxHelp";

const displayFormat = (obj) => {
	if(obj === null) {
		return <span data-null>&lt;null&gt;</span>;
	} else {
		return `${obj}`;
	}
};

var fetching = false;

const fetchModuleInfo = async (setState, api) => {
	if(!fetching) {
		fetching = true;
		//get zoho modules and their field names&types
		try {
			const modules = await api.get(`${process.env.REACT_APP_BASE_URL}/api/v1/zohoModules`);
			setState(modules.data.data);
		} catch(err) {
			console.log(err);
			setState({isError:true, errorMsg:err.toString()});
		}
		fetching = false;
	}
};

const PreviewUpdateTabs = ({previewData, rootModule, ignoreRecords}) => {
	const forceUpdate = useForceUpdate();
	const [tabValue, setTabValue] = useState(0);
	const [ignoreAll, ] = useState(new Set());

	const handleTabChange = (event, newTabValue) => {
		setTabValue(newTabValue);
	};

	const handleCheckedAll = (curTab) => (event, checked) => {
		if(checked) {
			ignoreAll.delete(curTab);
			ignoreRecords.delete(curTab);
		} else {
			ignoreAll.add(curTab);
			ignoreRecords.set(curTab, new Set(previewData.CurrentValues[curTab]["id"]) );
		}
		forceUpdate();
	};

	const handleCheckedRow = (curTab, rowId) => (event, checked) => {
		let uncheckedSet = ignoreRecords.get(curTab);
		if(!uncheckedSet) {
			uncheckedSet = new Set();
			ignoreRecords.set(curTab,uncheckedSet);
		}
		if(checked) {
			uncheckedSet.delete(rowId);
		} else {
			uncheckedSet.add(rowId);
		}
		forceUpdate();
	};

	if (!previewData) {
		return <></>;
	}

	const keys = Object.keys(previewData.CurrentValues);

	return <div className="bulkUpdatePreview">
		<TabContext value={tabValue.toString()}>
			<Tabs
				value={tabValue}
				variant="scrollable"
				onChange={handleTabChange}
			>
				{
					keys.map((key,index)=>
						<Tab className="previewTab" key={index} label={key || `[${rootModule}]`}/>
					)
				}
			</Tabs>
			{
				keys.map((curTab,index)=> {
					let len = 0;
					for (let someField in previewData.CurrentValues[curTab]) {
					//someField = any arbitrary key, don't care which
						len = previewData.CurrentValues[curTab][someField].length;
						break;
					}
					const oKeys = moveToFront("id",Object.keys(previewData.CurrentValues[curTab]));
					const checkingDisabled = !previewData.OverwrittenValues[curTab];
					return <TabPanel value={index.toString()} key={index} index={index}>
						<div className="previewWrapper">
							<table className="previewTable">
								<thead>
									<tr>
										{
											oKeys.map((fieldName,colIndex)=> {
												if(fieldName === "id") {
													return <th key={colIndex} className="checkbox">
														<Checkbox
															disabled={checkingDisabled}
															checked={!ignoreAll.has(curTab)}
															onChange={handleCheckedAll(curTab)}
														/>
													</th>;
												}
												return <th key={colIndex}>
													{fieldName}
												</th>;
											})
										}
									</tr>
								</thead>
								<tbody>
									{
										Array.from({ length: len }, (_, rowIndex) => {
											const rowId = previewData.CurrentValues[curTab]["id"][rowIndex];
											const checked = !ignoreRecords.get(curTab)?.has(rowId);
											return <tr key={rowIndex} data-checked={presentIf(checked)}>
												{
													oKeys.map((fieldName,colIndex) => {
														const newVal = previewData.CurrentValues[curTab][fieldName][rowIndex];
														if(fieldName === "id") {
															return <td key={colIndex} className="checkbox">
																<Tooltip title={`Zoho id: ${rowId}`} arrow placement="right">
																	<span> {/*span is wrapper that allows Tooltip to operate even when checkbox is disabled*/}
																		<Checkbox
																			disabled={checkingDisabled}
																			checked={checked}
																			onChange={handleCheckedRow(curTab,newVal)}
																		/>
																	</span>
																</Tooltip>
															
															</td>;
														}
														const oldVal = previewData.OverwrittenValues[curTab]?.[fieldName]?.[rowIndex];
														let toDisplay = displayFormat(newVal);
														let className = "";
														if(oldVal !== undefined) {
															toDisplay = <>{displayFormat(oldVal)}<span className="newValue"> ➔ {toDisplay}</span></>;
															className = "updatedCell";
														}

														return <td className={className} key={colIndex}>{toDisplay}</td>;
													})
												}
											</tr>;
										}
										)
									}
								</tbody>
							</table>
						</div>
					</TabPanel>;
				})
			}
		</TabContext>
	</div>;
};

const SaveQueries = (queries) => {
	try {
		const toSave = [];
		queries.forEach((query, name)=>{
			query.name = name;
			toSave.push(query);
		});
		JSON.stringify(toSave);
		localStorage.setItem("zohoBulk/savedQueries",toSave);
	} catch(e) {
		console.log("Error saving queries:",e);
	}
};

const LoadQueries = () => {
	const queries = localStorage.getItem("zohoBulk/savedQueries");
	const loaded = new Map();
	if (queries) {
		try {
			const array = JSON.parse(queries);
			for(const query in array) {
				loaded.set(query.name,query);
			}
		} catch(e) {
			console.log("Error loading queries:",e);
		}
	}
	return loaded;
};

// eslint-disable-next-line no-unused-vars
const [GET,SET] = [0,1];
const UpdaterContext = React.createContext();

const RootModulePicker = ({rootModuleState}) => {
	const api = useAdminTools();

	const [moduleInfo, setModuleInfo] = React.useContext(UpdaterContext);

	const [rootModule, setRootModule] = rootModuleState;

	const handleChange = React.useCallback((event)=>{
		setRootModule({
			name: event.target.value,
			value: moduleInfo[event.target.value],
		});
	},[moduleInfo, setRootModule]);

	var child = null;

	useEffect(()=>{
		if(!moduleInfo) {
			fetchModuleInfo(setModuleInfo, api);
		}
	},[moduleInfo,api]);

	if(rootModuleState[GET]) {
		child = rootModule.name;
	} else {
		if (!moduleInfo) {
			child = <CircularProgress />;
		} else if (moduleInfo.isError) {
			child = moduleInfo.errorMsg;
		} else {
			child = <Select fullWidth onChange={handleChange} className="moduleSelect" value="">
				{
					Object.keys(moduleInfo).map((apiName)=>
						<MenuItem key={apiName} value={apiName}>{apiName}</MenuItem>
					)
				}
			</Select>;
		}
	}
	return <div className="rootModuleStack">
		<b>FROM MODULE</b>
		<br />
		{child}
	</div>;
};

const getButton = (str, onClick) => {
	let Icon = null;
	if(str.length > 0) {
		Icon = ClearIcon;
	} else {
		Icon = DeleteIcon;
	}
	return <InputAdornment position="end">
		<IconButton
			className="lineClearButton"
			onClick={onClick}
		>
			<Icon />
		</IconButton>
	</InputAdornment>;
};

const LinesEditor = ({title,editLinesState,className}) => {
	const [editLines,setEditLines] = editLinesState;
	const lineEditFields = editLines.map((lineStr,i)=>{
		const edit = (event) => {
			editLines[i] = event.target.value;
			setEditLines([...editLines]);
		};
		const onAdornmentClick = (/*event*/) => {
			if(lineStr.length > 0) {
				edit({target:{value:""}});
			} else {
				const copy = [...editLines];
				copy.splice(i,1);
				setEditLines(copy);
			}
		};
		return <div key={i} className="lineEditor">
			<TextField fullWidth type="input" size="small"
				value={lineStr} onChange={edit}
				InputProps={{
					endAdornment: getButton(lineStr,onAdornmentClick),
					spellCheck: false,
				}}
			/>
		</div>;
	});
	const newedit = useCallback((event) => {
		setEditLines([...editLines,event.target.value]);
	},[editLines]);
	lineEditFields.push(<div key={lineEditFields.length} className="lineEditor">
		<TextField fullWidth type="input" size="small"
			value={""} onChange={newedit}
			InputProps={{
				spellCheck:false,
			}}
		/>
	</div>);
	return <div className={className}>
		<b>{title}</b>
		<br />
		{lineEditFields}
	</div>;
};

const BulkUpdate = (/*{props}*/) => {
	const api = useAdminTools();

	const [savedQueries,setSavedQueries] = React.useState(LoadQueries());

	const [rootModule,setRootModule] = React.useState();
	const moduleInfoState = React.useState();

	const [filterLines,setFilterLines] = React.useState([]);
	const [setLines,setSetLines] = React.useState([]);

	const [warnPreview, setWarnPreview] = React.useState(false);
	const [warnPersist, setWarnPersist] = React.useState(false);
	const [waiting, setWaiting] = React.useState(null);
	const [previewData, setPreviewData] = React.useState(null);
	const [AlertStack,newAlert] = useAlertStack();
	const [ignoreRecords, setIgnoreRecords] = useState(new Map());

	(()=>{})(savedQueries,setSavedQueries,SaveQueries);

	let editors = null;
	if (rootModule) {
		editors = <>
			<LinesEditor
				className = "whereStack"
				title = "WHERE"
				editLinesState={[filterLines,setFilterLines]}
			/>
			<LinesEditor
				className = "setStack"
				title = "SET"
				editLinesState={[setLines,setSetLines]}
			/>
		</>;
	}

	const handleClear = useCallback(() => {
		setRootModule(undefined);
		setFilterLines([]);
		setSetLines([]);

	},[setRootModule,setFilterLines,setSetLines]);

	const handleSave = useCallback(() => {

	},[]);

	const handleLoad = useCallback(() => {

	},[]);
	
	const handleRun = useCallback(()=>{
		setWarnPreview(true);
	},[]);

	const handlePreviewConfirm = useCallback((confirmed) => {
		if (confirmed) {
			if(!waiting){
				const runQueryUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/zohoBulk?dryRun=true`;
				
				const body = {
					rootModule:rootModule.name,
					where:filterLines.filter((line)=>line),
					set:setLines.filter((line)=>line),
				};
				setWaiting("massPreview");
				api.post(runQueryUrl,body,{headers:api.noCache}).then((response)=>{
					if (response.status === 200) {
						setWarnPreview(false);
						setPreviewData(response.data.data);
						setWarnPersist(true);
						return response;
					} else {
						throw response;
					}
				}).catch((error)=>{
					if (error.response?.status === 404) {
						setWarnPreview(false);
						newAlert("info", "Query affects 0 records", "No records match");
						return error.response;
					} 
					let message = `${error}`;
					if (error.response?.status < 500) {
						const msgStr = error.response?.data.data?.message;
						if(msgStr) {
							message = <>{message}<hr />{msgStr}</>;
						}
					}
				
					console.log(error);
					newAlert("error", <>Error running update: {message}</>, "Update Error");
				}).finally(()=>{
					setWaiting(null);
				});
			}
		} else {
			setWarnPreview(false);
		}
	},[filterLines,setLines,rootModule,api]);

	const handlePersistConfirm = useCallback((confirmed) => {
		const cleanup = () => {
			setPreviewData(undefined);
			setIgnoreRecords(new Map());
			setWarnPersist(false);
		};

		if (confirmed) {
			if(!waiting){
				const runQueryUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/zohoBulk`;
				const body = {
					rootModule:rootModule.name,
					where:filterLines.filter((line)=>line),
					set:setLines.filter((line)=>line),
				};
				if(ignoreRecords.size > 0) {
					const ignore = {};
					for(const [k,v] of ignoreRecords) {
						ignore[k] = Array.from(v);
					}
					body.ignore = ignore;
				}

				console.log(body);
				setWaiting("massPersist");
				api.post(runQueryUrl,body,{headers:api.noCache}).then((response)=>{
					if (response.status === 200) {
						cleanup();
						newAlert("success", "Updates completed", "Updated");
						return response;
					} else {
						throw response;
					}
				}).catch((error)=>{
					if (error.response.status === 404) {
						setWarnPersist(false);
						newAlert("info", "Query affected 0 records", "No records matched");
						return error.response;
					}
					let message = `${error}`;
					if (error.response?.status < 500) {
						const msgStr = error.response?.data.data?.message;
						if(msgStr) {
							message = <>{message}<br />{msgStr}</>;
						}
					}
				
					console.log(error);
					newAlert("error", <>Error running update: {message}</>, "Update Error");
				}).finally(()=>{
					setWaiting(null);
				});
			}
		} else {
			cleanup();
		}
	},[filterLines,setLines,rootModule,ignoreRecords,api]);

	let dialogWaiting, runButton;
	if (waiting) {
		dialogWaiting = <CircularProgress />;
	}
	if(rootModule){
		runButton = <Button
			className="menuButton runButton"
			onClick={handleRun}
		>
			Run
		</Button>;
	}
	return <><PageHeader title={"Bulk Update Zoho"} />
		<UpdaterContext.Provider value={moduleInfoState}>
			<Stack className="bulkUpdater">
				<Stack className="menuBar" direction="row" spacing={2}>
					<Button
						className="menuButton clearButton"
						onClick={handleClear}
					>
					Clear
					</Button>
					<Button
						className="menuButton"
						onClick={handleSave}
					>
					Save
					</Button>
					<Button
						className="menuButton"
						onClick={handleLoad}
					>
					Load
					</Button>
				</Stack>
				<RootModulePicker rootModuleState={[rootModule,setRootModule]}/>
				{editors}
				{runButton}
			</Stack>
		</UpdaterContext.Provider>
		
		<ChoiceDialog
			dialogOpen={warnPreview}
			title="Preview updates?"
			confirmElement={dialogWaiting ?? <>Preview</>}
			cancelElement={dialogWaiting ?? <>Cancel</>}
			onDialogClose={handlePreviewConfirm}
		>
			Preview mass update?
			<br />
			If the query affects many records, it can take a few minutes to complete.
		</ChoiceDialog>

		<ChoiceDialog
			dialogOpen={warnPersist}
			title="Confirm Updates?"
			confirmElement={dialogWaiting ?? <>Confirm</>}
			cancelElement={dialogWaiting ?? <>Cancel</>}
			onDialogClose={handlePersistConfirm}
		>
			Persist updates?
			<br />
			Check the data below to ensure that the updates are correct.<br />
			<PreviewUpdateTabs
				previewData={previewData}
				rootModule={rootModule?.name}
				ignoreRecords={ignoreRecords}
			/>
			<br />
			Confirm whether to bulk update in Zoho.
			<br />
			<strong>This operation cannot be undone.</strong>
		</ChoiceDialog>

		<AlertStack />
	</>;
};

export default BulkUpdate;