import React, { useEffect, useState, useCallback } from "react";

import { Button, CircularProgress } from "@mui/material";

import PageHeader from "components/Common/PageHeader";
import useAdminTools from "adminapi";
import { downloadFile, readCsv } from "helperFuncs/dataHelp";
import ChoiceDialog from "components/Common/ChoiceDialog";
import "./AutoReplenish.css";
import "components/Common/CsvTable.css";

const AutoReplenish = (/*{props}*/) => {
	const api = useAdminTools();
	const [report,setReport] = useState(null);
	const [confirmAutoreplenish,setConfirmAutoreplenish] = useState(false);

	useEffect(()=>{
		if(report === null) {
			const runReportUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/reports/autoreplenish?dryRun=true`;
			api.post(runReportUrl,undefined,{
				headers:{
					"Accept":"application/octet-stream",
					...api.noCache
				}
			}).then(data=>{
				setReport(readCsv(data.data));
			}).catch(error=>{
				setReport({error:error});
				console.log(error);
			});
		}
	},[report]);

	const handleConfirmAutoreplenish = (confirmed) => {
		setConfirmAutoreplenish(false);
		if(confirmed) {
			const runReportUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/reports/autoreplenish`;
			setReport({loading:true});
			api.post(runReportUrl,undefined,{
				headers:{
					"Accept":"application/octet-stream",
					...api.noCache
				}
			}).then(data=>{
				const report = readCsv(data.data);
				report.success = true;
				setReport(report);
			}).catch(error=>{
				setReport({error:error});
				console.log(error);
			});
		}
	};

	const handleAutoreplenish = () => {
		setConfirmAutoreplenish(true);
	};

	const saveResults = useCallback(() => {
		const blob = new Blob([report.raw], {type: "text/csv"});
		const filename = "autoreplenishResults.csv";
		downloadFile(blob, filename);
	},[report]);

	let content;

	if(report === null || report.loading) {
		content = <div className="centeredLoading">
			<CircularProgress />Executing Autoreplenish...
		</div>;
	} else if(report.error) {
		content = <div className="centeredError">
			Autoreplenish error:
			<br />
			{report.error?.toString()}
			<br />
			<Button className="retryButton"
				onClick={()=>setReport(null)}
			>
				Retry
			</Button>
		</div>;
	} else {
		let message;
		if(report.success) {
			message = <>
				Autoreplenish completed
				<br />
				<Button className="downloadButton"
					onClick={saveResults}
				>
					Download Results
				</Button>
			</>;
		} else {
			message = <>
				Below is the result of a dry run of autoreplenish.
				<br />
				Click the button below to perform the full autoreplenish.
				<br />
				<Button className="executeButton"
					onClick={handleAutoreplenish}
				>
					Execute Autoreplenish
				</Button>
			</>;
		}
		content =  <div className="autoreplenishReport">
			{message}
			<br />
			<br />
			<table className="csvTable">
				<thead>
					<tr>
						{report.csvHeaders.map((str)=><th key={str}>{str}</th>)}
					</tr>
				</thead>
				<tbody>
					{report.csvData.map((row,i)=><tr key={i}>
						{row.map((str)=><td key={str}>{str}</td>)}
					</tr>)}
				</tbody>
			</table>
		</div>;
	}

	return <>
		<PageHeader title="Run Autoreplenish" />

		{content}
		
		<ChoiceDialog
			dialogOpen={confirmAutoreplenish}
			title={"Confirm Run Autoreplenish"}
			confirmElement={<>Run Autoreplenish</>}
			cancelElement={<>Back</>}
			onDialogClose={handleConfirmAutoreplenish}
		>
			Really run autoreplenish?<br />
			This will update minute balances in zoho.
		</ChoiceDialog>
	</>;
};

export default AutoReplenish;
