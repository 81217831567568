import React from "react";

import { CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import { TreeItem, SimpleTreeView } from "@mui/x-tree-view";

import "./SessionView.css";

export const errorStatus = { isError: true };
export const readyStatus = {};
export const loadingStatus = { isLoading: true };

export const SessionContext = React.createContext({
	sessionStatusState: loadingStatus,
});

export const parseSessionStatus = (data, flag) => {
	
	const users = data.users;
	let sessions = [];

	let licenses = new Set();

	//Optimization point: request only sessions with matching sessionFlags.
	//Not done here as CM doesn't yet support such a request.
	for (let sessionName in data.sessions) {
		const curSession = data.sessions[sessionName];
		if (curSession.sessionflags & flag) {
			const retSession = {
				name: sessionName,
				owner: curSession.owner,
				users: Object.entries(curSession.licenseid).map(([licenseNo, userName]) => {
					licenses.add(licenseNo);
					return {
						licenseNo: licenseNo,
						userName: userName,
						machineId: users[licenseNo]?.machineid
					};
				}),
			};
			sessions.push(retSession);
		}
	}

	//if optimization is needed:
	//   return data=undefined, and get numUsers & numSessions from CM directly
	//   then return data=session[] only after tree is expanded.
	return {
		numUsers: licenses.size,
		numSessions: sessions.length,
		data: sessions,
	};
};

const SessionTree = ({sessions}) => {

	if (sessions.length === 0) {
		return <>No sessions open.</>;
	}

	const items = sessions.map(session => {
		const users = session.users.map(user => {
			const id = session.name +"/"+ user.licenseNo;
			const label = <div className="sessionUser">{user.licenseNo} &lt;{user.userName}&gt;</div>;
			return <TreeItem key={id} itemId={id} label={label}/>;
		});
		const id = session.owner +"/"+ session.name;
		const label = <div className="sessionNode">[{session.owner}] {session.name}</div>;
		return <TreeItem key={id} itemId={id} label={label}>
			{users}
		</TreeItem>;
	});

	return <div className="sessionTree">
		<SimpleTreeView
			slots={{
				collapseIcon: ExpandMoreIcon,
				expandIcon: ChevronRightIcon,
				endIcon: ()=><CircleIcon className="treeLeaf"/>
			}}>
			{items}
		</SimpleTreeView>
	</div>;
};


const SessionView = ({viewStatus}) => {

	const {sessionStatusState} = React.useContext(SessionContext);
	const [sessionStatus] = sessionStatusState;

	const viewContent = React.useMemo(() => {
		if (viewStatus.isLoading) {
			return <div className="message"><CircularProgress /></div>;
		} else if (viewStatus.isError) {
			return <div className="message">Connection Error.</div>;
		} else {
			return <SessionTree sessions={sessionStatus.data} />;
		}
	}, [viewStatus, sessionStatus, sessionStatus.data]);

	return <div className="sessionView">
		{viewContent}
	</div>;
};

export default SessionView;