import React, { useContext } from "react";
import axios from "axios";

import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button, IconButton, Grid, Typography, CircularProgress, Divider } from "@mui/material";

import SessionView, { SessionContext, parseSessionStatus, loadingStatus, errorStatus } from "components/Common/SessionView";
import { setState } from "helperFuncs/dataHelp";
import { plural } from "helperFuncs/jsxHelp";
import { useCounter } from "customHooks";
import currentUsers from "static/1.svg";
import "./WidgetLayout.css";

const SESSION_BRIDGE = 0x10;
const SESSION_EDIT = 0x20;
const SESSION_STENOLINK = 0x40;
const SESSION_REALTIME = 0x80;

const StatusBadges = (/*{props}*/) => {
	const {sessionStatusState} = useContext(SessionContext);
	const [sessionStatus,] = sessionStatusState;
	const {numUsers, numSessions} = sessionStatus;
	return <>
		<Typography>
			<span className="widgetBadge usersBadge">
				<span className="badgeValue">
					{numUsers}
				</span>
				&nbsp;{plural(numUsers,"User")}
			</span>
			<span className="widgetBadge sessionsBadge">
				<span className="badgeValue">
					{numSessions}
				</span>
				&nbsp;{plural(numUsers,"Session")}
			</span>
		</Typography>
	</>;
};

const LoadingBadge = (/*{props}*/) => <>
	<Typography>
		<span className="widgetBadge loadingBadge">
			<CircularProgress size='20pt' />
			<span className="badgeValue">&nbsp;</span>
			Loading...
		</span>
	</Typography>
</>;

const ErrorBadge = (/*{props}*/) => <>
	<Typography>
		<span className="widgetBadge errorBadge">
			<ErrorIcon className="badgeIcon" />
			<span className="badgeValue">&nbsp;</span>
			Connection Error
		</span>
	</Typography>
</>;

const Widget = ({widgetClass,logo,title}) => {
	const {sessionStatusState} = useContext(SessionContext);

	const [sessionStatus,] = sessionStatusState;
	
	const [expand, setExpand] = React.useState(false);

	let badges;
	if (sessionStatus === errorStatus) {
		badges = <ErrorBadge />;
	} else if (sessionStatus === loadingStatus) {
		badges = <LoadingBadge />;
	} else {
		badges = <StatusBadges />;
	}

	let icon;
	if(expand) {
		icon = <ExpandLessIcon />;
	} else {
		icon = <ExpandMoreIcon />;
	}

	const handleExpand = React.useCallback(() => {
		setExpand(!expand);
	}, [expand, setExpand]);

	const sessionView = React.useMemo(() => {
		if (expand) {
			return <SessionView viewStatus={sessionStatus} />;
		} else {
			return null;
		}
	}, [expand, sessionStatus]);

	return <Grid item md={6} lg={3} className="wrapper">
		<div className={`widget ${widgetClass}`}>
			<div className="logoWrapper">
				{logo}
			</div>
			<div className="content">
				<Typography className="header">
					{title}
				</Typography>
				{badges}
			</div>
			<div className="divider">
				<Divider />
			</div>
			<IconButton className="expandToggle" onClick={handleExpand}>
				{icon}
			</IconButton>
			{sessionView}
		</div>
	</Grid>;
};


const CmServers = Object.entries(JSON.parse(process.env.REACT_APP_CM_SERVERS)).map(
	([region,url]) => ({region,url})
);

const WidgetLayout = (/*{props}*/) => {
	const [selectedServer,setSelectedServer] = React.useState(CmServers[0]);
	const [selectedCounter,incrementCounter] = useCounter();
	const bridgeStatusState = React.useState(loadingStatus);
	const teamEditingStatusState = React.useState(loadingStatus);
	const stenoStatusState = React.useState(loadingStatus);
	const realtimeStatusState = React.useState(loadingStatus);

	const setAllStatuses = (status) => {
		setState(bridgeStatusState,status);
		setState(teamEditingStatusState,status);
		setState(stenoStatusState,status);
		setState(realtimeStatusState,status);
	};

	React.useEffect(() => {
		axios.post(`${selectedServer.url}/admin`, {
			func: "get_data",
			data: {
				usercount: false,
				users: true,
				conncount: false,
				conns: false,
				sessioncount: false,
				sessions: true,
				config: false,
			}
		}).then((response) => {
			//NOTE: Consider that this response may be very large:
			// Jeremy has convinced me that it's probably fine. But if it becomes an issue:
			//   Submit a PR to modify the /admin endpoint to get the numbers on the dashboard
			// without sending large objects, or use local storage to cache the response from the server.
			
			const data = response.data;

			setState(bridgeStatusState,parseSessionStatus(data, SESSION_BRIDGE));
			setState(teamEditingStatusState,parseSessionStatus(data, SESSION_EDIT));
			setState(stenoStatusState,parseSessionStatus(data, SESSION_STENOLINK));
			setState(realtimeStatusState,parseSessionStatus(data, SESSION_REALTIME));
		}).catch((error) => {
			console.log(error);
			setAllStatuses(errorStatus);
		});
	}, [selectedServer,selectedCounter]);

	const bridgeWidget = <SessionContext.Provider
		value={{
			sessionStatusState: bridgeStatusState,
			flag:SESSION_BRIDGE,
		}}>
		<Widget widgetClass="bridgeWidget"
			title={<>Bridge Mobile</>}
			logo={<img src={currentUsers} alt="Bridge Mobile" />}
		/>
	</SessionContext.Provider>;

	const teamEditingWidget = <SessionContext.Provider
		value={{
			sessionStatusState: teamEditingStatusState,
			flag:SESSION_EDIT,
		}}>
		<Widget widgetClass="teamEditingWidget"
			title={<>Team Editing</>}
			logo={<img src={currentUsers} alt="Team Editing" />}
		/>
	</SessionContext.Provider>;

	const stenoWidget = <SessionContext.Provider
		value={{
			sessionStatusState: stenoStatusState,
			flag: SESSION_STENOLINK,
		}}>
		<Widget widgetClass="stenoWidget"
			title={<>StenoLink</>}
			logo={<img src={currentUsers} alt="StenoLink" />}
		/>
	</SessionContext.Provider>;

	const realtimeWidget = <SessionContext.Provider
		value={{
			sessionStatusState: realtimeStatusState,
			flag: SESSION_REALTIME,
		}}>
		<Widget widgetClass="realtimeWidget"
			title={<>Realtime</>}
			logo={<img src={currentUsers} alt="Realtime" />}
		/>
	</SessionContext.Provider>;

	const handleServerClick = (server) => {
		return () => {
			setAllStatuses(loadingStatus);
			setSelectedServer(server);
			incrementCounter();
		};
	};

	return <div className="widgetLayout">
		<Typography className="cmStatusBar">
			Connection Magic Server Status:
			{
				CmServers.map(server => <Button
					key={server.region}
					className={server===selectedServer?"selectedServerBtn":"nonselectedServerBtn"}
					onClick={handleServerClick(server)}
				>
					{server.region}
				</Button>)
			}
		</Typography>
		<Grid container>
			{bridgeWidget}
			{teamEditingWidget}
			{stenoWidget}
			{realtimeWidget}
		</Grid>
	</div>;
};

export default WidgetLayout;
