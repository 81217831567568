import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import { LoginContext, TokenValidated } from "components/Login/loginCommon";
import { sessionGetAllScopeValues, sessionHasScope, sessionScope } from "session/scope";

const ScopeAccessor = ({ children, scopesRequired, scopeValuesRequired }) => {
	const { refreshToken } = useContext(LoginContext);

	const sRequired = scopesRequired ?? [];
	const vRequired = scopeValuesRequired ?? [];

	let allowed = TokenValidated(refreshToken) && sessionHasScope(...sRequired);

	if(allowed) {
		for(let scopeReq of vRequired) {
			let scopeMet = false;
			for(let val of sessionGetAllScopeValues(scopeReq[0])) {
				if(val >= scopeReq[1]) {
					scopeMet = true;
					break;
				}
			}
			if(!scopeMet){
				allowed = false;
				break;
			}
		}
	}

  
	if (refreshToken && sRequired) {
		return <div className="scope" data-access={sessionScope().join(" ")}>
			<Outlet>
				{children}
			</Outlet>
		</div>;
	} else {
		return <div className="scope" data-access={sessionScope().join(" ")}>
			<div>Permission Denied</div>
			<br />
			<a href='/'>Back to dashboard</a>
		</div>;
	}
};

export default ScopeAccessor;
