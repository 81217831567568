export const LicenseTypes = {
	NONE: "",
	EDIT: "Edit",
	EDIT_BRIDGE: "Edit+Bridge",
	STUDENT: "Student",
	TRAN: "Tran",
	TRAN_BRIDGE: "Tran+Bridge",
	ACCUCAP: "AccuCap",
	ACCUCAP_BRIDGE: "AccuCap+Bridge",
	NOECLIPSE_BRIDGE: "NoEclipse+Bridge",
	//
	RSR: "RSR",
	RSR_BRIDGE: "RSR+Bridge",
	RSR_TRAN: "RSR+Tran",
	RSR_TRAN_BRIDGE: "RSR+Tran+Bridge",
	RSR_ACCUCAP: "RSR+AccuCap",
	RSR_ACCUCAP_BRIDGE: "RSR+AccuCap+Bridge",
};

export const SpeechSteno = {
	NONE: "",
	SPEECH: "Speech",
	STENO: "Steno",
	BOTH: "Both",
};

export const SpeechServices = {
	GLOBAL: "global",
	GOOGLE_SPEECH: "google_speech",
	SPEECHMATICS: "speechmatics",
	WATSON: "watson",
	AZURE: "azure",
	KENSHO: "kensho",
};