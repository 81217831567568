import React from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Stack, CircularProgress, AlertTitle } from "@mui/material";

import ZohoAuth from "components/Login/loginZoho";
import GoogleAuth from "components/Login/loginGoogle";
import { LoginContext, TokenValidated, ClearSessionData } from "components/Login/loginCommon";
import { sessionHasScope } from "session/scope";
import Advantage_img from "static/Advantage_Software.png";
import "./login.css";

const auths = [ZohoAuth, GoogleAuth];

export const AuthPage = (/*{props}*/) => {
	const {
		errorMsg,
		setErrorMsg,
		errorTitle,
		refreshToken,
		loggingInto,
	} = React.useContext(LoginContext);

	const closeErrorMsg = () => {
		setErrorMsg(null);
	};

	const validToken = Boolean(TokenValidated(refreshToken));

	const navigate = useNavigate();

	React.useEffect(() => {
		if (validToken) {
			if(sessionHasScope("read")) {
				navigate("/dashboard");
			} else {
				navigate("/tempcode");
			}
		}
	}, [navigate, validToken]);

	let errorStack = null;
	if (errorMsg) {
		errorStack = <Stack className="errorStack" spacing={2}>
			<Alert onClose={closeErrorMsg} severity='error'>
				<AlertTitle>
					<strong>{errorTitle}</strong>
				</AlertTitle>
				{errorMsg}
			</Alert>
		</Stack>;
	}

	if (loggingInto) {
		return <div className="loginForm">
			<span><CircularProgress size='20pt' />Logging in with {loggingInto}.</span>
		</div>;
	}
	return <div className="loginForm">
		<img src={Advantage_img} alt="Advantage Software"/>
		<span className="siteTitle">Admin Tools Portal</span>
		<br />
		{errorStack}
		<br />
		{
			auths.map((auth,i) => <auth.AuthButton key={i} validToken={validToken}/>)
		}
	</div>;
};

export function LoginManager({children}) {
	const [errorMsg, setErrorMsg] = React.useState(null);
	const [loggingInto, setLoggingInto] = React.useState(null);
	const [picture, setPicture] = React.useState(localStorage.getItem("login/picture"));

	const [accessToken, setAccessToken] = React.useState(
		TokenValidated(localStorage.getItem("login/access")) && localStorage.getItem("login/access")
	);
	const [refreshToken, setRefreshToken] = React.useState(
		TokenValidated(localStorage.getItem("login/refresh")) && localStorage.getItem("login/refresh")
	);
    
	const navigate = useNavigate();

	const signOut = React.useCallback(() => {
		ClearSessionData();
		setAccessToken("");
		setRefreshToken("");
		setPicture("");
		navigate("/");
	},[navigate]);

	const signIn = React.useCallback((data) => {
		const accessToken = TokenValidated(data?.access_token);
		if(accessToken && TokenValidated(data?.refresh_token)) {

			setPicture(data.picture);
			localStorage.setItem("login/picture", data?.picture ?? "");
          
			setAccessToken(data.access_token);
			localStorage.setItem("login/access", data.access_token);

			setRefreshToken(data.refresh_token);
			localStorage.setItem("login/refresh", data.refresh_token);

			localStorage.setItem("login/name", data?.name ?? "");
			localStorage.setItem("login/scope",accessToken.scope);

			if(sessionHasScope("read")) {
				navigate("/dashboard");
			} else {
				navigate("/tempcode");
			}
		} else {
			console.log("returned tokens not valid");
			signOut();
		}
	},[navigate, signOut]);


	return <LoginContext.Provider
		value={{
			signIn,
			signOut,
			loggingInto,
			setLoggingInto,
			errorMsg,
			setErrorMsg,
			picture,
			accessToken,
			setAccessToken,
			refreshToken,
		}}>
		{children}
	</LoginContext.Provider>;
}