import { useState } from "react";

export function useForceUpdate() {
	//evaluates to a function that toggles a react state `()=>s(g=>!g)` when called.
	return (([,s])=>()=>s(g=>!g))(useState());
}

export function useCounter(startingValue) {
	const [val, setVal] = useState(startingValue ?? 0);
	return [val, ()=>setVal(val+1)];
}