import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

import { Button, CircularProgress } from "@mui/material";

import PageHeader from "components/Common/PageHeader";
import useAdminTools from "adminapi";
import { downloadFile, readCsv } from "helperFuncs/dataHelp";
import "./UsedMinutes.css";

const UsedMinutes = (/*{props}*/) => {
	const api = useAdminTools();
	const [report,setReport] = useState(null);
	const [attemptNo,setAttemptNo] = useState(0);

	useEffect(()=>{
		const runReportUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/reports/usedServices`;
		setReport({loading:true});
		api.get(runReportUrl,{
			headers: {
				"Accept":"application/octet-stream",
			}
		}).then(data=>{
			setReport(readCsv(data.data));
		}).catch(error=>{
			setReport({error:error});
			console.log(error);
		});
	},[attemptNo]);

	const saveResults = useCallback(() => {
		const blob = new Blob([report.raw], {type: "text/csv"});
		const filename = "usedServicesReport.csv";
		downloadFile(blob, filename);
	},[report]);

	let content;

	if(report === null || report.loading) {
		content = <div className="centeredLoading">
			<CircularProgress />Generating Report...
		</div>;
	} else if(report.error) {
		content = <div className="centeredError">
			Used Services Report error:
			<br />
			{report.error?.toString()}
			<br />
			<Button className="retryButton"
				onClick={()=>setAttemptNo(attemptNo+1)}
			>
				Retry
			</Button>
		</div>;
	} else {
		const rsrLic = report.csvData.filter(([,,isRsr]) => isRsr === "true");
		const noRsrLic = report.csvData.filter(([,,isRsr]) => isRsr !== "true");
		const linkToLic = (row,i)=>{
			const licno = row[1];
			return <Link key={i} to={`/license/${licno}`}><li>
				{licno}
			</li></Link>;
		};
		content =  <div className="usedMinutesReport">
			<br />
			<br />
			<Button className="downloadButton"
				onClick={saveResults}
			>
				Download Results
			</Button>
			<br />
			<br />
			<div className="rsrList">
				RSR Licenses
				<ul>
					{rsrLic.map(linkToLic)}
				</ul>
			</div>
			<div className="rsrList">
				Non-RSR Licenses
				<ul>
					{noRsrLic.map(linkToLic)}
				</ul>
			</div>
		</div>;
	}

	return <>
		<PageHeader title="Monthly Usage Report" />
		{content}
	</>;
};

export default UsedMinutes;
