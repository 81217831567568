import React from "react";
import { Link } from "react-router-dom";

import { dateFormat } from "helperFuncs/dateFormat";
import { presentIf } from "helperFuncs/jsxHelp";
import "./SearchedLicenseCard.css";

const deadTileContent = <>
	<div className="searchRow">
		&nbsp;
	</div>
	<div className="searchRow">
		Status:<span>Dead key</span>
	</div>
</>;

const SearchedLicenseCard = ({
	licenseNo,
	customerId,
	customerName,
	licExpDate,
	supExpDate,
	deadkey,
	version,
}) => {
	const now = new Date().getTime();

	let tileContent;
	if (deadkey) {
		tileContent = deadTileContent;
	} else {
		let dateValue;
		let dateLabel;
		let dateContent;
		if (supExpDate) {
			dateValue = supExpDate;
			dateLabel = "Support Expires:";
		} else if (licExpDate) {
			dateValue = licExpDate;
			dateLabel = "License Expires:";
		} else {
			dateValue = null;
		}
		if (dateValue === null) {
			dateContent = <>&nbsp;</>;
		} else {
			dateContent = <>
				{dateLabel}
				<span className="value" data-expired={presentIf(dateValue.getTime() < now)}>
					{dateFormat(dateValue)}
				</span>
			</>;
		}
		let nameValue;
		let nameLabel;
		let nameContent;
		if (customerName) {
			nameValue = customerName;
			nameLabel = "Customer:";
		} else if (customerId) {
			nameValue = customerId;
			nameLabel = "Customer ID:";
		} else {
			nameValue = null;
		}
		if (nameValue === null) {
			nameContent = <>&nbsp;</>;
		} else {
			nameContent = <>
				{nameLabel}
				<span className="value">
					{nameValue}
				</span>
			</>;
		}
		tileContent = <>
			<div className="searchRow">
				{nameContent}
			</div>
			<div className="searchRow">
				{dateContent}
			</div>
		</>;
	}

	return <Link className="searchedLicenseCard" to={`/license/${licenseNo}`}>
		<div className="tile" data-deadkey={presentIf(deadkey)}>
			<div className="searchRow">
				{licenseNo}
				<div className="version">v{version.toFixed(1)}</div>
			</div>
			{tileContent}
		</div>
	</Link>;
};
export default SearchedLicenseCard;
