import React, { useEffect, useState, useCallback } from "react";

import { Button, CircularProgress, TextField, MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { hyphenatedDate } from "helperFuncs/dateFormat";
import { SpeechServices } from "helperFuncs/enums";
import { downloadFile, generateCsv } from "helperFuncs/dataHelp";
import PageHeader from "components/Common/PageHeader";
import useAdminTools from "adminapi";
import "./MonthlyUsage.css";
import "components/Common/CsvTable.css";

const parseMonthlyReport = (report, selectedService) => {
	const csvHeaders = [
		"License No.",
		"Customer",
		"Contact Name",
		"Minutes Used"
	];
	const csvData = [];

	for(let licenseId in report) {
		const lic = report[licenseId];
		let used = lic.usage;
		if(used) {
			if(selectedService !== SpeechServices.GLOBAL) {
				used = used.filter(u=>u.service===selectedService);
			}
			used = used.reduce((sum, u)=>sum+u.minutes_used,0);
			csvData.push([
				lic.license_no,
				lic.customer_number,
				lic.customer_name,
				used,
			]);
		}
	}

	return {
		csvHeaders,
		csvData,
	};
};

const MonthlyUsage = (/*{props}*/) => {
	const api = useAdminTools();
	const [report,setReport] = useState(null);
	const [selectedService,setSelectedService] = useState(SpeechServices.GOOGLE_SPEECH);
	const [reportMonth,setReportMonth] = useState(()=>{
		let date = new Date();
		date.setHours(0,0,0,0);
		date.setDate(1);
		return date;
	});

	const nextMonth = new Date(reportMonth);
	nextMonth.setMonth(nextMonth.getMonth()+1);

	useEffect(()=>{
		const runReportUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/reports/usage?start_date=${
			hyphenatedDate(reportMonth)}&end_date=${hyphenatedDate(nextMonth)}`;
		setReport({loading:true});
		api.get(runReportUrl,{
			headers: api.noCache
		}).then(data=>{
			setReport(parseMonthlyReport(data.data.report,selectedService));
		}).catch(error=>{
			setReport({error:error});
			console.log(error);
		});
	},[reportMonth,selectedService]);

	const handleSelectedService = (event) => {
		setSelectedService(event.target.value);
	};

	const saveResults = useCallback(() => {
		const blob = new Blob([generateCsv(report)], {type: "text/csv"});
		const inMonth = new Date(reportMonth);
		inMonth.setDate(inMonth.getDate()+15); //set date in middle of month
		const datePart = reportMonth.toLocaleString("default",{month:"short", year: "numeric"});
		const filename = `${selectedService}-${datePart}.csv`;
		downloadFile(blob, filename);
	},[report,reportMonth,selectedService]);

	let content;

	if(report === null || report.loading) {
		content = <div className="centeredLoading">
			<CircularProgress />Generating Report...
		</div>;
	} else if(report.error) {
		content = <div className="centeredError">
			Usage Report error:
			<br />
			{report.error?.toString()}
			<br />
			<Button className="retryButton"
				onClick={()=>setReport(null)}
			>
				Retry
			</Button>
		</div>;
	} else {
		content = <div className="monthlyUsageReport">
			<br />
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					views={["month", "year"]}
					label="Report Month"
					openTo="month"
					value={reportMonth}
					onChange={setReportMonth}
					slotProps={{ 
						textField:{
							size:"small",
							className:"reportParam"
						}
					}}
				/>
			</LocalizationProvider>
			<br />
			<br />
			<TextField select
				value={selectedService}
				label="Speech Service"
				size='small'
				className="reportParam"
				onChange={handleSelectedService}
			>
				<MenuItem value={SpeechServices.GLOBAL}>All Services</MenuItem>
				<MenuItem value={SpeechServices.GOOGLE_SPEECH}>Google Speech</MenuItem>
				<MenuItem value={SpeechServices.SPEECHMATICS}>Speechmatics</MenuItem>
				<MenuItem value={SpeechServices.WATSON}>Watson</MenuItem>
				<MenuItem value={SpeechServices.AZURE}>Azure</MenuItem>
				<MenuItem value={SpeechServices.KENSHO}>Kensho</MenuItem>
			</TextField>
			<br />
			<br />
			<Button className="downloadButton"
				onClick={saveResults}
			>
							Download Results
			</Button>
			<br />
			<br />
			<table className="csvTable">
				<thead>
					<tr>
						{report.csvHeaders.map((str)=><th key={str}>{str}</th>)}
					</tr>
				</thead>
				<tbody>
					{report.csvData.map((row,i)=><tr key={i}>
						{row.map((str)=><td key={str}>{str}</td>)}
					</tr>)}
				</tbody>
			</table>
		</div>;
	}

	return <>
		<PageHeader title="Monthly Usage Report" />
		{content}
	</>;
};

export default MonthlyUsage;
