import React, { useCallback, useMemo } from "react";

import { dateFormat, daysToMs } from "helperFuncs/dateFormat";
import "./ActivityCheckerGraph.css";

const parseOverlaps = (overlaps) => {
	return overlaps ?? [];
};

const parseActivity = (activity) => {
	if (!activity || activity.length === 0) {
		return [];
	}

	let lines = [];
	let j = 0;
	let open = [];
  
	activity.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

	for (let i = 0; i < activity.length; i++) {
		while (j < i && activity[j].endTime.getTime() <= activity[i].startTime.getTime()) {
			let l = activity[j].line;
			open.push(l);

			j++;

			//only needed to make it look nicer after a period of no activity.
			//can be removed if desired.
			if (j === i) {
				open.sort((a, b) => b - a);
			}
		}

		if (open.length === 0) {
			let l = lines.length;
			activity[i].line = l;
			lines[l] = [activity[i]];
		}
		else {
			let l = open.pop();
			activity[i].line = l;
			lines[l].push(activity[i]);
		}
	}
	return lines;
};



const getStyleClasses = (date) => {
	if (date.getDate() === 1) {//first of month
		if (date.getMonth === 0) {//January
			return "vertLine yearLine";
		}
		return "vertLine monthLine";
	} else if (date.getDay() === 0) {//Sunday
		return "vertLine weekLine";
	} else {
		return "vertLine";
	}
};

const firstN = n => Array.from(Array(n), (_, k) => k + 1);

const backDays = (n, endDate) => {
	const ret = new Date(endDate);
	ret.setDate(ret.getDate() - n);
	return ret;
};

const calcTranslateX = (start, end, position, timeInRange) => {
	const amt = (end.getTime() - position.getTime() - timeInRange) / timeInRange;
	return `translateX(${100 * amt}%)`;
};


const ActivityCheckerGraph = ({
	selectStartState,
	selectEndState,
	viewDays,
	activity,
	overlaps,
	startDate,
	endDate,
	position,
}) => {

	const svgRef = React.useRef();
	const rootGRef = React.useRef();

	const detectRectRef = React.useRef();

	const tooltipRef = React.useRef();

	const sessionTdRef = React.useRef();
	const startTdRef = React.useRef();
	const endTdRef = React.useRef();

	const [selectStart, setSelectStart] = selectStartState;
	const [selecting, setSelecting] = React.useState(false);
	const [selectEnd, setSelectEnd] = selectEndState;

	const activityLines = useMemo(() => parseActivity(activity), [activity]);
	const overlapsParsed = useMemo(() => parseOverlaps(overlaps), [overlaps]);

	const getTimeClicked = useCallback((event) => {
		const svgBox = svgRef.current.getBoundingClientRect();

		const gTransform = rootGRef.current.style.transform;

		const translateMatch = /translateX\((-?(\d*\.)?\d*)%\)/g;
		let offset = translateMatch.exec(gTransform);
		if (offset === null) {
			console.log("Couldn't find offset from transform");
			offset = 0;
		} else {
			offset = Number(offset[1]); //in percent
		}

		let fromRight = svgBox.width - event.clientX + svgBox.x; //px from right of element
		fromRight += offset / 100 * svgBox.width; //px from endDate
		fromRight /= svgBox.width / viewDays; // days from endDate
		fromRight = Math.floor(daysToMs(fromRight)); //milliseconds from endDate
		return new Date(endDate.getTime() - fromRight);
	}, [endDate, viewDays]);

	const selectionStart = useCallback((event) => {
		if (!selecting) {
			setSelectStart(getTimeClicked(event));
			setSelectEnd(getTimeClicked(event));
			setSelecting(true);
		}
	}, [selecting, getTimeClicked, setSelectStart, setSelectEnd]);

	const selectionUpdate = useCallback((event) => {
		if (selecting) {
			setSelectEnd(getTimeClicked(event));
		}
	}, [selecting, getTimeClicked, setSelectEnd]);

	const selectionEnd = useCallback((event) => {
		if (selecting) {
			setSelectEnd(getTimeClicked(event));
			setSelecting(false);
		}
	}, [selecting, getTimeClicked, setSelectEnd]);

	const updateTooltip = (event) => {
		let tooltip = tooltipRef.current;

		if (!tooltip.classList.contains("displayedTooltip")) {
			sessionTdRef.current.innerText = event.target.dataset.sessionName;
			startTdRef.current.innerText = event.target.dataset.start;
			endTdRef.current.innerText = event.target.dataset.end;

			tooltip.classList.add("displayedTooltip");
		}
		tooltip.style.left = `${event.clientX}px`;
		tooltip.style.top = `${event.clientY}px`;
	};

	const genActivityRects = useCallback(() => {
		let rects = [];
		const endTime = endDate.getTime();

		for (let i = 0; i < activityLines.length; i++) {

			let yPos = `${(i * 2 + 0.2)}em`;
			let hVal = "1.6em";

			for (let session of activityLines[i]) {
				let timeDiff = session.startTime.getTime() - endTime; //ms
				timeDiff = timeDiff / 1000 / 60 / 60 / 24; //days
        
				let sessionDiff = session.endTime.getTime() - session.startTime.getTime(); //ms
				sessionDiff = sessionDiff / 1000 / 60 / 60 / 24; //days

				let dayScale = 100 / viewDays;
        
				let xPos = `${100 + timeDiff * dayScale}%`;
				let wVal = `${sessionDiff * dayScale}%`;

				rects.push(<rect key={"actRect" + session.session_name} className="activityRect" data-session-name={session.session_name}
					data-start={session.startTime.toLocaleString()} data-end={session.endTime.toLocaleString()}
					x={xPos} y={yPos} width={wVal} height={hVal}
					onMouseDown={selectionStart}
					onMouseUp={selectionEnd}
					onMouseEnter={updateTooltip}
					onMouseMove={(event) => { moveTooltip(event); selectionUpdate(event); }}
					onMouseLeave={clearTooltip}
				/>);
			}
		}
		return rects;
	}, [endDate, activityLines, selectionEnd, selectionStart, selectionUpdate]);

	const genOverlapRects = useCallback((classSuffix) => {

		if (!overlapsParsed)
			return [];

		let rectClassName = `overlapRect${classSuffix ?? ""}`;

		const endTime = endDate.getTime();

		let rects = [];

		for (let overlap of overlapsParsed) {
			let timeDiff = overlap.startTime.getTime() - endTime; //ms
			timeDiff = timeDiff / 1000 / 60 / 60 / 24; //days

			let overlapDiff = overlap.endTime.getTime() - overlap.startTime.getTime(); //ms
			overlapDiff = overlapDiff / 1000 / 60 / 60 / 24; //days

			let dayScale = 100 / viewDays;

			let xPos = `${100 + timeDiff * dayScale}%`;
			let wVal = `${overlapDiff * dayScale}%`;

			rects.push(<rect key={`overlapRect${xPos}`} className={rectClassName}
				x={xPos} y={0} width={wVal} height="100%"
			/>);
		}
		return rects;
	}, [endDate, overlapsParsed, viewDays]);

	const genSelectionRect = useCallback(() => {
		if (!selectStart || !selectEnd) {
			return null;
		}
		const st = selectStart.getTime();
		const ed = selectEnd.getTime();

		const [first, second] = ed > st ? [st, ed] : [ed, st];

		let timeDiff = first - endDate.getTime(); //ms
		timeDiff = timeDiff / 1000 / 60 / 60 / 24; //days

		let selectDiff = second - first; //ms
		selectDiff = selectDiff / 1000 / 60 / 60 / 24; //days

		let dayScale = 100 / viewDays;

		let xPos = `${100 + timeDiff * dayScale}%`;
		let wVal = `${selectDiff * dayScale}%`;
		return <rect key="selectRect" className="selectRect"
			x={xPos} y={0} width={wVal} height="100%"
		/>;
	}, [endDate, selectStart, selectEnd, viewDays]);

	const moveTooltip = (event) => {
		let tooltip = tooltipRef.current;
		tooltip.style.left = `${event.clientX}px`;
		tooltip.style.top = `${event.clientY}px`;
		return false;
	};

	const clearTooltip = () => {
		let tooltip = tooltipRef.current;
		tooltip.classList.remove("displayedTooltip");
	};

	let displayedMessage = null;

	if (activityLines === null) {
		displayedMessage = "Loading...";
	} else if (activityLines.length === 0) {
		displayedMessage = "No activity found for this license";
	}

	const diff = endDate.getTime() - startDate.getTime();

	if (isNaN(diff) || diff <= 0) {
		displayedMessage = "Invalid date range";
	}

	// To calculate the no. of days between two dates
	// The round call is needed to account for leap hours/seconds/etc.
	// The script below fails if daysInRange is not an integer
	const daysInRange = Math.round(diff / daysToMs(1));

	if (daysInRange > 366) {
		displayedMessage = "Date range too large";
	}

	const fullRender = !displayedMessage;

	const overlapRectsUnder = React.useMemo(() => {
		//console.log("rendering olUnder");
		if (fullRender) {
			return genOverlapRects("Under");
		} else {
			return null;
		}
	}, [fullRender, genOverlapRects]);

	const horizontalLines = React.useMemo(() => {
		//console.log("rendering horizLines");
		if (fullRender) {
			return firstN(activityLines.length).map(
				n => <line key={`horizLine${n}`} className="horizLine"
					x1="-1000000%" x2="100%" y1={`${n * 2}em`} y2={`${n * 2}em`}
				/>
			);
		} else {
			return null;
		}
	}, [activityLines.length, fullRender]);

	const dateLines = React.useMemo(() => {
		//console.log("rendering dateLines");
		if (fullRender) {
			return Array.from(Array(daysInRange),
				(_, k) => { return { n: k, date: backDays(k + 1, endDate), x: `${(6 - k) * 100 / viewDays}%` }; })
				.flatMap(
					a => [
						<line key={`vertLine${a.n}`} className={getStyleClasses(a.date)} //line-date={a.date}
							x1={a.x} x2={a.x} y1="0%" y2="100%" />,
						<text key={`dateText${a.n}`} className={`${getStyleClasses(a.date)} dateText`} x={a.x} y={"100%"}>
							{dateFormat(a.date)}
						</text>
					]
				);
		} else {
			return null;
		}
	}, [daysInRange, fullRender, endDate, viewDays]);

	const activityRects = React.useMemo(() => {
		//console.log("rendering actRects");
		if (fullRender) {
			return genActivityRects();
		} else {
			return null;
		}
	}, [fullRender, genActivityRects]);

	const overlapRectsOver = React.useMemo(() => {
		//console.log("rendering olOver");
		if (fullRender) {
			return genOverlapRects();
		} else {
			return null;
		}
	}, [fullRender, genOverlapRects]);

	const selectionRect = React.useMemo(() => {
		//console.log("rendering selectRect");
		if (fullRender) {
			return genSelectionRect();
		} else {
			return null;
		}
	}, [fullRender, genSelectionRect]);

	if (!fullRender) {
		return <div className="activityCheckerGraph">
			<div className="displayedMessage">
				{displayedMessage}
			</div>
		</div>;
	}

	return <div className="activityCheckerGraph">
		<div className="activityTooltip" ref={tooltipRef}>
			<table>
				<tbody>
					<tr>
						<td>Session:</td>
						<td ref={sessionTdRef}>None Selected</td>
					</tr>
					<tr>
						<td>Start:</td>
						<td ref={startTdRef}>None Selected</td>
					</tr>
					<tr>
						<td>End:</td>
						<td ref={endTdRef}>None Selected</td>
					</tr>
				</tbody>
			</table>
		</div>
		<svg ref={svgRef} style={{ height: `${activityLines.length * 2 + 1}em` }}>
			<rect className="detectRect" ref={detectRectRef}
				onMouseDown={selectionStart}
				onMouseMove={selectionUpdate}
				onMouseUp={selectionEnd}
			/>
			<g ref={rootGRef}
				style={{ transform: calcTranslateX(startDate, endDate, position, daysToMs(viewDays)) }}
			>
				{
					overlapRectsUnder
				}
				{
					horizontalLines
				}
				{
					dateLines
				}
				{
					activityRects
				}
				{
					overlapRectsOver
				}
				{
					selectionRect
				}
			</g>
		</svg>
	</div>;
};

export default ActivityCheckerGraph;
