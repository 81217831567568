import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Divider, Dialog, Stack, Button } from "@mui/material";

import "./ListDialog.css";

const ListDialog = ({
	dialogOpen,
	handleDialog,
	children,
	title,
	listItems,
}) => {

	const closeDialog = () => {
		handleDialog(null);
	};

	const handleCloseButton = () => {
		handleDialog(null);
	};

	let items = [...(listItems ?? [])];

	return <Dialog
		open={dialogOpen}
		onClose={closeDialog}
		className="listDialog"
	>
		<div className="header">
			<div className="tag" />
			<span className="closeButton">
				<CloseIcon onClick={handleCloseButton} />
			</span>
			<div className="title">
				{title}
			</div>
		</div>
		
		<Divider />

		<div className="content">
			{children}
		</div>

		<Stack spacing={1} direction='column'>
			{
				items.map((item,i) => 
					<Button
						className="itemButton"
						key={i}
						variant='outlined'
						onClick={()=>handleDialog(item)}
					>
						{item}
					</Button>
				)
			}
		</Stack>
	</Dialog>;
};

export default ListDialog;
