import React, { useState, useEffect, createContext, useMemo, useCallback, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import { CircularProgress, InputBase, Button, IconButton, Drawer, TextField, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EditIcon from "@mui/icons-material/Edit";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import PageHeader from "components/Common/PageHeader";
import WidgetLayout from "components/Common/WidgetLayout";
import { sessionHasScope, sessionGetAllScopeValues } from "session/scope";
import useAdminTools from "adminapi";
import { dateFormat } from "helperFuncs/dateFormat";
import { presentIf, valueIf } from "helperFuncs/jsxHelp";
import ListDialog from "components/Common/ListDialog";
import SearchedLicenseCard from "components/Dashboard/SearchedLicenseCard";
import "./Dashboard.css";


export const searchContext = createContext();

const FilterDateValue = {
	NONE: "",
	LICENSE_EXPIRY: "License Expiry",
	SUPPORT_EXPIRY: "Support Expiry",
};

const SortBy = {
	NONE: "",
	CUTSOMER_ID: "Customer",
	LICENSE_NO: "License No",
	LICENSE_EXPIRY: "License Expiry",
	SUPPORT_EXPIRY: "Support Expiry",
};

const SortFunc = {
	[SortBy.NONE]: (licenses,) => licenses,
	[SortBy.CUTSOMER_ID]: (licenses, sortAscending) => {
		let toSort = [...licenses];
		let compare = sortAscending ?
			(a, b) => a.customer_id - b.customer_id :
			(a, b) => b.customer_id - a.customer_id;
		return toSort.sort(compare);
	},
	[SortBy.LICENSE_NO]: (licenses, sortAscending) => {
		let toSort = [...licenses];
		let compare = sortAscending ?
			(a, b) => a.license_no.localeCompare(b.license_no) :
			(a, b) => b.license_no.localeCompare(a.license_no);
		return toSort.sort(compare);
	},
	[SortBy.LICENSE_EXPIRY]: (licenses, sortAscending) => {
		let toSort = [...licenses];

		let compare = sortAscending ?
			(a, b) => {
				const aTime = a.license_expire_date ? new Date(a.license_expire_date).getTime() : Infinity;
				const bTime = b.license_expire_date ? new Date(b.license_expire_date).getTime() : Infinity;
				return aTime - bTime;
			} :
			(a, b) => {
				const aTime = a.license_expire_date ? new Date(a.license_expire_date).getTime() : Infinity;
				const bTime = b.license_expire_date ? new Date(b.license_expire_date).getTime() : Infinity;
				return bTime - aTime;
			};
		return toSort.sort(compare);
	},
	[SortBy.SUPPORT_EXPIRY]: (licenses, sortAscending) => {
		let toSort = [...licenses];

		let compare = sortAscending ?
			(a, b) => {
				const aTime = a.support_expire_date ? new Date(a.support_expire_date).getTime() : -Infinity;
				const bTime = b.support_expire_date ? new Date(b.support_expire_date).getTime() : -Infinity;
				return aTime - bTime;
			} :
			(a, b) => {
				const aTime = a.support_expire_date ? new Date(a.support_expire_date).getTime() : -Infinity;
				const bTime = b.support_expire_date ? new Date(b.support_expire_date).getTime() : -Infinity;
				return bTime - aTime;
			};
		return toSort.sort(compare);
	}
};

const loadingDiv = <div className="centeredLoading">
	<CircularProgress />Searching...
</div>;

const noResultsDiv = <div className="noResultsDiv">No Results Found</div>;


const Dashboard = () => {
	const api = useAdminTools();

	const [pageTitle,] = useState("Admin Tools Dashboard");
	const [searchString, setSearchString] = useState(localStorage.getItem("search/searchString") ?? "");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const navigate = useNavigate();

	const [searchedLicenses, setSearchedLicenses] = useState(null);

	const [filterVersion, setFilterVersion] = useState("");
	let effectiveFilterVersion = filterVersion;
	if (effectiveFilterVersion) {
		effectiveFilterVersion = filterVersion.lastIndexOf(".");
		if (effectiveFilterVersion === -1) { //no dot
			let num = parseInt(filterVersion, 10);
			effectiveFilterVersion = num ? `${num}.x` : "";
		} else if (filterVersion.length - 1 === effectiveFilterVersion) { //dot is last character
			let num = parseInt(filterVersion, 10);
			effectiveFilterVersion = num ? `${num}.x` : "";
		} else { //minor version specified
			if (filterVersion.charAt(filterVersion.length - 1) === "x") { //.x specified
				effectiveFilterVersion = filterVersion;
			} else {
				let num = Number(filterVersion).toFixed(1);
				effectiveFilterVersion = num || "";
			}
		}
	}

	const [filterDeadkeys, setFilterDeadkeys] = useState(null); // null | true | false.

	const [filterDateValue, setFilterDateValue] = useState(FilterDateValue.NONE);
	const [filterStartDate, setFilterStartDate] = useState(null);
	const [filterEndDate, setFilterEndDate] = useState(null);

	const filterStartTime = filterStartDate ? new Date(filterStartDate).getTime() || -Infinity : -Infinity;
	const filterEndTime = filterEndDate ? new Date(filterEndDate).getTime() || Infinity : Infinity;

	const [sortBy, setSortBy] = useState(SortBy.NONE);
	const [sortAscending, setSortAscending] = useState(true); // true | false

	let [licensesPerPage, setLicensesPerPage] = useState(-1);
	
	const [reportSelectDialogOpen, setReportSelectDialogOpen] = useState(false);

	const runReportItems = useMemo(()=>new Map([
		[<><AutoModeIcon />&nbsp;Autoreplenish speech minutes</>,()=>{
			navigate("/reports/autoreplenish");
		}],
		[<><CalendarMonthIcon />&nbsp;Monthly usage report</>,()=>{
			navigate("/reports/monthly");
		}],
		[<><WatchLaterIcon />&nbsp;Get all speech licenses</>,()=>{
			navigate("/reports/usedMinutes");
		}]
	]),[navigate]);

	const filteredLicenses = useMemo(() => {
		let result = searchedLicenses;
		if (result === null) {
			return null;
		}
		if (filterDeadkeys !== null) {
			if (filterDeadkeys) {
				result = result.filter(license => license.deadkey);
			} else {
				result = result.filter(license => !license.deadkey);
			}
		}
		if (effectiveFilterVersion) {
			if (effectiveFilterVersion.indexOf("x") > 0) { //any minor version
				let v = Number(effectiveFilterVersion.split(".")[0]);
				result = result.filter(license => Math.floor(license.version) === v);
			} else { //specific minor version
				let v = Number(effectiveFilterVersion);
				result = result.filter(license => license.version === v);
			}
		}
		if (filterDateValue !== FilterDateValue.NONE) {
			switch (filterDateValue) {
			case FilterDateValue.LICENSE_EXPIRY:
				result = result.filter(license => {
					let time = license.license_expire_date ?
						new Date(license.license_expire_date).getTime()
              || Infinity : Infinity;
					return time >= filterStartTime && time <= filterEndTime;
				});
				break;
			case FilterDateValue.SUPPORT_EXPIRY:
				result = result.filter(license => {
					let time = license.license_support_date ?
						new Date(license.license_expire_date).getTime()
              || -Infinity : -Infinity;
					return time >= filterStartTime && time <= filterEndTime;
				});
				break;
			default:
				throw new Error(`Unknown filter date ${filterDateValue}`);
			}
		}
		return result;
	}, [searchedLicenses, filterDeadkeys, effectiveFilterVersion, filterDateValue, filterStartTime, filterEndTime]);

	const sortedLicenses = useMemo(() => {
		if (filteredLicenses === null) {
			return null;
		}
		return SortFunc[sortBy](filteredLicenses, sortAscending);
	}, [filteredLicenses, sortAscending, sortBy]);

	const handleCreateClick = () => {
		navigate("/license/create");
	};

	const handleTempcodeClick = () => {
		navigate("/tempcode");
	};

	const handleBulkUpdateClick = () => {
		navigate("/bulkUpdate");
	};

	const handleRunReportClick = () => {
		setReportSelectDialogOpen(true);
	};
	const handleRunReportDialog = (item) => {
		if(item === null) {
			setReportSelectDialogOpen(false);
		} else {
			const onReportSelected = runReportItems.get(item);
			onReportSelected?.();
		}
	};

	const changePage = ({ selected }) => {
		setCurrentPage(selected);
	};

	const handleSort = (event) => {
		setSortBy(event.target.value);
	};

	const handleExpiry = (event) => {
		setFilterDateValue(event.target.value);
	};

	const handleLicensesPerPage = useCallback((event) => {
		let value = Number(event.target.value);
		setLicensesPerPage(value);
		if (value === -1) {
			value = 60;
		}
		if (sortedLicenses === null) {
			setTotalPages(1);
		} else {
			const pages = Math.ceil(sortedLicenses.length / value);
			setTotalPages(pages);
		}
	}, [sortedLicenses]);

	const selectLicensesPerPage = useMemo(() => {
		return <select
			className="sort"
			onChange={handleLicensesPerPage}
			value={licensesPerPage.toString()}
		>
			<option value='-1'>Licenses/Page</option>
			<option value='60'>60</option>
			<option value='120'>120</option>
			<option value='210'>210</option>
			<option value='300'>300</option>
		</select>;
	}, [licensesPerPage, handleLicensesPerPage]);

	licensesPerPage = Math.max(licensesPerPage, 60);

	const searchOffset = currentPage * licensesPerPage;

	const handleFilterStartDate = (date) => {
		setFilterStartDate(date);
	};

	const handleFilterEndDate = (date) => {
		setFilterEndDate(date);
	};

	const handleOrder = (event) => {
		setSortAscending(event.target.value === "ascending");
	};

	const [state, setState] = useState({
		right: false,
	});

	const handleFilterVersion = (event) => {
		if (event.target.validity.valid) {
			setFilterVersion(event.target.value);
		}
	};

	const handleFilterNoDeadkeys = (/*event*/) => {
		setFilterDeadkeys((prev) => {
			//prev and filterDeadkeys are boolean or null.
			//Do not omit the '=== false', as we don't want to match null values.
			return prev === false ? null : false;
		});
	};

	const handleFilterDeadkeys = (/*event*/) => {
		setFilterDeadkeys((prev) => {
			//Will work without '=== true', but kept to mirror above check
			return prev === true ? null : true;
		});
	};

	const changeSearch = (newSearchString) => {
		setSearchString(newSearchString);
		if (newSearchString === "") {
			localStorage.removeItem("search/searchString");
			setSearchedLicenses(null);
		}
	};

	const clearSearch = () => {
		changeSearch("");
	};

	const handleSearch = (event) => {
		changeSearch(event.target.value);
	};

	const [, setSearchController] = useState(null);

	const performSearch = useCallback(async (value) => {
    
		let controller = new AbortController();
		setSearchController((prevController) => {
			if (prevController !== null) {
				//If a request is already in progress, abort it and start a new one.
				prevController.abort();
			}
			return controller;
		});

		if (value) {
			localStorage.setItem("search/searchString", value );
			const searchUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/license/search?query=${value}`;
			api.get(searchUrl, {
				signal: controller.signal
			}).then(response => {
				if (response.status === 200) {
					let data = response.data.data;
					setSearchedLicenses(data.licenses ?? []);
					setCurrentPage(0);
				} else {
					throw response;
				}
			}).catch(error => {
				//If a request is aborted, it returns with the message 'canceled'
				//Since this is expected behavior, do not treat it like an error.
				if (error.message === "canceled") {
					return;
				}
				localStorage.setItem("search/searchString", "");
			}).finally(() => {
				setSearchController(null);
			});
		} else {
			setSearchedLicenses(null);
			setCurrentPage(0);
			setSearchController(null);
		}
	}, [api]);

	useEffect(() => {
		performSearch(searchString);
	}, [searchString, performSearch]);

	const resetSortsAndFilters = () => {
		setFilterStartDate(null);
		setFilterEndDate(null);
		setFilterDateValue(FilterDateValue.NONE);
		setFilterVersion("");
		setFilterDeadkeys(null);
		setSortBy(SortBy.NONE);
		setSortAscending(true);
		if (sortedLicenses === null) {
			setTotalPages(1);
		}
		else {
			const pages = Math.ceil(sortedLicenses.length / licensesPerPage);
			setTotalPages(pages);
		}
	};

	const toggleDrawer = (anchor, open) => (event) => {
		if (event === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};


	const prioritizeDisplayLicenseExpiry =
		(sortBy === SortBy.LICENSE_EXPIRY) ||
		(sortBy !== SortBy.SUPPORT_EXPIRY && filterDateValue === FilterDateValue.LICENSE_EXPIRY);


	const SortOptionsDrawer = () => (
		<form className="dashboardSortOptions">
			<Grid container
				className="sortOptionsDrawer"
				role='presentation'
			>
				<Grid item md={12}>Sort By:</Grid>
				<Grid item md={12}>
					<select
						onChange={handleSort}
						className="sortBy"
						value={sortBy}
					>
						<option value={SortBy.NONE}>Sort By</option>
						<option value={SortBy.CUTSOMER_ID}>Customer ID</option>
						<option value={SortBy.LICENSE_NO}>License Number</option>
						<option value={SortBy.LICENSE_EXPIRY}>License Expiry</option>
						<option value={SortBy.SUPPORT_EXPIRY}>Support Expiry</option>
					</select>
				</Grid>
				<Grid item md={1} className="radio">
					<input
						name="order"
						type='radio'
						value='ascending'
						checked={sortAscending}
						onChange={handleOrder}
					/>
				</Grid>
				<Grid item md={2}>Ascending</Grid>
				<Grid item md={2} />
				<Grid item md={1}>
					<input
						name="order"
						type='radio'
						value='descending'
						checked={!sortAscending}
						onChange={handleOrder}
					/>
				</Grid>
				<Grid item md={4}>Descending</Grid>
				<Grid item md={12}>Filter By:</Grid>
				<Grid item md={1}>
					{/* Do not omit '=== true' below*/}
					<input
						value={filterDeadkeys === true}
						checked={filterDeadkeys === true}
						type='checkbox'
						label="DeadKey"
						onChange={handleFilterDeadkeys}
					/>
				</Grid>
				<Grid item md={4}>Only&nbsp;Deadkeys</Grid>
				<Grid item md={1}>
					{/* Do not omit '=== false' below*/}
					<input
						value={filterDeadkeys === false}
						checked={filterDeadkeys === false}
						type='checkbox'
						label="No Deadkeys"
						onChange={handleFilterNoDeadkeys}
					/>
				</Grid>
				<Grid item md={5}>No Deadkeys</Grid>
				<Grid item md={12}>
					<TextField
						label="License Version"
						variant='outlined'
						size='small'
						//
						inputProps={{
							pattern: "(^$|\\d{1,2}(\\.(\\d|x)?)?)",
							value: filterVersion,
						}}
						onChange={handleFilterVersion}
					/>
				</Grid>
				<Grid item md={12}>
					<select
						onChange={handleExpiry}
						className="sortBy"
						value={filterDateValue}
					>
						<option value={FilterDateValue.NONE}>Filter by Date</option>
						<option value={FilterDateValue.LICENSE_EXPIRY}>License Expiry</option>
						<option value={FilterDateValue.SUPPORT_EXPIRY}>Support Expiry</option>
					</select>
				</Grid>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Grid item md={6}>
						<DatePicker
							label="Start"
							placeholder="Pick a start date"
							value={filterStartDate}
							disabled={filterDateValue === FilterDateValue.NONE}
							onChange={handleFilterStartDate}
							slotProps={{ 
								textField:{
									value:filterStartDate,
									size:"small",
								}
							}}
						/>
					</Grid>
					<Grid item md={6}>
						<DatePicker
							label="End"
							placeholder="Pick an end date"
							value={filterEndDate}
							disabled={filterDateValue === FilterDateValue.NONE}
							onChange={handleFilterEndDate}
							slotProps={{ 
								textField:{
									value:filterEndDate,
									size:"small",
								}
							}}
						/>
					</Grid>
				</LocalizationProvider>
				<Grid item md={6}>
					<Button className="resetButton" onClick={resetSortsAndFilters}>
						Reset
					</Button>
				</Grid>
			</Grid>
		</form>
	);

	let navButtons = [];

	if (sessionHasScope("report")) {
		navButtons.push(<div key="runReportBtn" className="navButton reportButton">
			<Button
				onClick={handleRunReportClick}
				
			>
				<SummarizeIcon />&nbsp;<span>Run a report</span>
			</Button>
		</div>);
	}

	if (sessionHasScope("zohoBulk")) {
		navButtons.push(<div key="bulkUpdateBtn" className="navButton bulkButton">
			<Button
				onClick={handleBulkUpdateClick}
			>
				<EditIcon /><span>&nbsp;Bulk-update Zoho</span>
			</Button>
		</div>);
	}

	if (sessionGetAllScopeValues("tempcode").length > 0) {
		navButtons.push(<div key="tempcodeBtn" className="navButton tempcodeButton">
			<Button
				onClick={handleTempcodeClick}
			>
				<VpnKeyIcon /><span>&nbsp;Generate a tempcode</span>
			</Button>
		</div>);
	}

	if (sessionHasScope("write")) {
		navButtons.push(<div key="newLicBtn" className="navButton createButton">
			<Button
				onClick={handleCreateClick}
			>
				<AddIcon /><span>&nbsp;Create a new license</span>
			</Button>
		</div>);
	}

	


	let searchResults;
	if (searchString !== "") {
		if (sortedLicenses === null) {
			searchResults = loadingDiv;
		} else if (sortedLicenses.length === 0) {
			searchResults = noResultsDiv;
		} else {
			const licenseCards = sortedLicenses.slice(searchOffset, searchOffset + licensesPerPage).map(
				(license, index) => <SearchedLicenseCard key={index}
					licenseNo={license.license_no}
					customerId={license.customer?.id}
					customerName={license.customer?.name}
					supExpDate={
						valueIf(
							license.support_expire_date && !prioritizeDisplayLicenseExpiry,
							new Date(license.support_expire_date)
						)
					}
					licExpDate={
						valueIf(
							license.license_expire_date,
							new Date(license.license_expire_date)
						)
					}
					deadkey={license.deadkey}
					version={license.version}
					currentPage={currentPage}
				/>
			);
			searchResults = <Suspense fallback={loadingDiv}>
				<div className="searchItems">
					{licenseCards}
				</div>
			</Suspense>;
		}
	}

	const drawerAnchor = "right";
	const drawerControllers = <>
		<Button
			className="sort"
			onClick={toggleDrawer(drawerAnchor, true)}
		>
			Sort &amp; Filter
		</Button>
		<Drawer
			anchor={drawerAnchor}
			open={state[drawerAnchor]}
			onClose={toggleDrawer(drawerAnchor, false)}
		>
			<SortOptionsDrawer />
		</Drawer>
	</>;

	let sortByString;
	if (sortBy === SortBy.NONE) {
		sortByString = null;
	} else {
		const ascDesc = sortAscending ? "Asc" : "Desc";
		sortByString = `Sorted By: ${sortBy} (${ascDesc})`;
	}

	let filterByString = "";
	if (filterDeadkeys !== null) {
		filterByString += filterDeadkeys ? "[Only Deadkeys]" : "[No Deadkeys]";
	}
	if (filterVersion) {
		filterByString += `[Version: ${effectiveFilterVersion}]`;
	}
	if (filterDateValue !== FilterDateValue.NONE) {
		filterByString += `[${filterDateValue}:${filterStartTime !== -Infinity ?
			dateFormat(new Date(filterStartDate)) : " ~ "
		}-${filterEndTime !== Infinity ?
			dateFormat(new Date(filterEndDate)) : " ~ "
		}]`;
	}
	if (filterByString === "") {
		filterByString = null;
	} else {
		filterByString = "Filtered By:" + filterByString;
	}

	let paginate;
	if (totalPages > 1) {
		paginate = <ReactPaginate
			previousLabel={"Previous"}
			nextLabel={"Next"}
			pageCount={totalPages}
			onPageChange={changePage}
			containerClassName={"pageButtons"}
			forcePage={currentPage}
		/>;
	} else {
		paginate = null;
	}

	let searchContent;
	if (searchString === "") {
		searchContent = null;
	} else {
		searchContent = <Grid container className="searchResults">
			<Grid item md={6} className="title">Search Results:</Grid>
			<Grid item md={4} className="licPerPage">
				{selectLicensesPerPage}
			</Grid>
			<Grid item md={2}>
				{drawerControllers}
			</Grid>
			<Grid item md={4} className="filterText">
				{sortByString}
			</Grid>
			<Grid item md={8} className="filterText">
				{filterByString}
			</Grid>
			{searchResults}
			{paginate}
		</Grid>;
	}

	return <>
		<PageHeader noBack title={pageTitle} />
		<div className="dashboard" data-searching={presentIf(searchString)}>
			<Box>
				<div className="navButtons">
					{navButtons}
				</div>
				<div className="searchBar">
					<div className="searchIcon">
						<SearchIcon />
					</div>
					<InputBase
						placeholder="License Search"
						onChange={handleSearch}
						value={searchString}
					/>
					<IconButton
						className="clearSearchIcon"
						onClick={clearSearch}
					>
						<ClearIcon />
					</IconButton>
				</div>
			</Box>
			
			{searchContent}

			<WidgetLayout />

		</div>
		<ListDialog
			dialogOpen = {reportSelectDialogOpen}
			handleDialog= {handleRunReportDialog}
			title="Run a Report"
			listItems={[...runReportItems.keys()]}
		>
		</ListDialog>
		<div id='appBuildInfo'>
			Admin Portal {process.env.REACT_APP_VERSION || "Local Build"}
		</div>
	</>;
};

export default Dashboard;
