import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "App";
import reportWebVitals from "reportWebVitals";
import { LoginManager } from "components/Login/login";

const docroot = document.getElementById("root");
const root = createRoot(docroot);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<LoginManager>
				<App />
			</LoginManager>
		</BrowserRouter>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
