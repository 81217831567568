import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "@mui/material";

import { LoginContext } from "components/Login/loginCommon";
import zohoLogo from "static/zohoLogo.png";
import "./loginZoho.css";
//*
const zohoRequestScopes = [
	"AaaServer.profile.READ",
	"ZohoCRM.coql.READ",
	"ZohoCRM.modules.READ",
	"ZohoCRM.modules.CREATE",
	"ZohoCRM.modules.UPDATE",
	"ZohoCRM.settings.modules.READ",
	"ZohoCRM.settings.fields.READ",
];

function zohoRedirect() {
	const redirectUri = `${window.location.protocol}//${window.location.host}`;
	window.location = "https://accounts.zoho.com/oauth/v2/auth" +
    "?response_type=code" +
    "&prompt=consent" +
    `&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}` +
    `&scope=${zohoRequestScopes.join(",")}` +
    "&access_type=offline" +
    `&redirect_uri=${redirectUri}`;
}

const ZohoAuth = {
	AuthButton: ({validToken}) => {
		const {
			setErrorMsg,
			signIn,
			signOut,
			setLoggingInto,
		} = useContext(LoginContext);

		let [searchParams,] = useSearchParams();

		const loginUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/auth/tokenpair`;

		const authCode = searchParams.get("code");

		React.useEffect(() => {
			if (authCode && !validToken) {

				setLoggingInto("Zoho");

				fetch(loginUrl, {
					mode: "cors",
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Auth-Token": `Zoho ${authCode}`
					},
				}).then(response => {
					if (response.ok) {
						return response.json();
					} else {
						if (response.status === 404 || response.status === 403)
							setErrorMsg("Unauthorized user");
						else
							setErrorMsg("Authentication error");
						throw Error("alreadySet");
					}
				}).then((json) => {
					if (json.status !== "success") {
						setErrorMsg("Authentication error");
						throw Error("alreadySet");
					}

					signIn(json.data);

				}).catch((error) => {
					if (error.message !== "alreadySet") {
						console.log(error);
						if (error.message === "Failed to fetch") {
							error.message = "Connection error";
						}
						setErrorMsg(error.message);
					}
					signOut();

				}).finally(() => {
					setLoggingInto(null);
				});
			}
		}, [authCode, validToken, loginUrl, setErrorMsg, signIn, signOut]);

		return <Button id='zohoButton'
			variant='contained'
			onClick={zohoRedirect}
		>
			Login with&nbsp;<br />
			<img alt="Zoho" src={zohoLogo} />
		</Button>;
	}
};

export default ZohoAuth;