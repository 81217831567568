/* eslint-disable react/prop-types */

import React from "react";
import { Routes, Route } from "react-router-dom";

import { AuthPage } from "components/Login/login";
import Dashboard from "components/Dashboard/Dashboard";
import LicenseEditor from "components/LicenseEditor/LicenseEditor";
import CreateTempcode from "components/CreateTempcode/CreateTempcode";
import ActivityChecker from "components/ActivityChecker/ActivityChecker";
import ScopeAccessor from "components/routes/ScopeAccessor";
import BulkUpdate from "components/BulkUpdate/BulkUpdate";
import AutoReplenish from "components/Reports/AutoReplenish";
import MonthlyUsage from "components/Reports/MonthlyUsage";
import UsedMinutes from "components/Reports/UsedMinutes";
import "App.css";

export default function App() {
	return <div>
		<Routes>
			<Route path="/" element={<AuthPage />} />
			<Route path="/login" element={<AuthPage />} />
			<Route path="/*" element={<ScopeAccessor scopeValuesRequired={["tempcode",2]} />} >
				<Route
					path="tempcode"
					element={<CreateTempcode />}
				/>
			</Route>
			<Route path="/*" element={<ScopeAccessor scopesRequired={["zohoBulk"]} />}>
				<Route
					path="bulkUpdate"
					element={<BulkUpdate />}
				/>
			</Route>
			<Route path="/*" element={<ScopeAccessor scopesRequired={["read"]} />}>
				<Route
					path="dashboard"
					element={<Dashboard />}
				/>
				<Route
					path="license/:licenseNo"
					element={<LicenseEditor name="edit" />}
				/>
			</Route>
			<Route path="/*" element={<ScopeAccessor scopesRequired={["write"]} />}>
				<Route
					path="license/create"
					element={<LicenseEditor name="create" />}
				/>
				<Route
					path="license/:licenseNo/activity"
					element={<ActivityChecker name="activity" />}
				/>
			</Route>
			<Route path="/*" element={<ScopeAccessor scopesRequired={["report"]} />}>
				<Route
					path="reports/autoreplenish"
					element={<AutoReplenish />}
				/>
			</Route>
			<Route path="/*" element={<ScopeAccessor scopesRequired={["report"]} />}>
				<Route
					path="reports/monthly"
					element={<MonthlyUsage />}
				/>
			</Route>
			<Route path="/*" element={<ScopeAccessor scopesRequired={["report"]} />}>
				<Route
					path="reports/usedMinutes"
					element={<UsedMinutes />}
				/>
			</Route>
		</Routes>
	</div>;
}