/*global google*/
import React, { useContext, useEffect, useCallback } from "react";

import { Button } from "@mui/material";

import { LoginContext } from "components/Login/loginCommon";
import "./loginGoogle.css";

const GoogleAuth = {
	AuthButton: ({validToken}) => {
		const {
			setErrorMsg,
			signIn,
			signOut,
			setLoggingInto,
		} = useContext(LoginContext);

		const loginUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/auth/tokenpair`;

		const onGoogleSignInSuccess = useCallback((response) => {
			//Login with google was successful, log into admin tools.
			setLoggingInto("Google");
			fetch(loginUrl, {
				mode: "cors",
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Auth-Token": `Google ${response.credential}`
				},
			}).then(response => {
				if (response.ok) {
					return response.json();
				} else {
					if (response.status === 404 || response.status === 403)
						setErrorMsg("Unauthorized user");
					else
						setErrorMsg("Authentication error");
					console.log(response);
					throw Error("alreadySet");
				}
			}).then((json) => {
				if (json.status !== "success") {
					setErrorMsg("Authentication error");
					throw Error("alreadySet");
				}

				signIn(json.data);

			}).catch((error) => {
				if (error.message !== "alreadySet") {
					console.log(error);
					if (error.message === "Failed to fetch") {
						error.message = "Connection error";
					}
					setErrorMsg(error.message);
				}
				signOut();

			}).finally(() => {
				setLoggingInto(null);
			});
		}, [loginUrl, setErrorMsg, signIn, signOut]);

		const initializeGSI = useCallback(() => {
			if (validToken) {
				return;
			} else {
				google.accounts.id.initialize({
					client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
					auto_select: false,
					callback: onGoogleSignInSuccess,
				});
				// Display the Sign In With Google Button
				google.accounts.id.renderButton(document.getElementById("googleButton"), {
					theme: "outline",
					size: "large",
					width: "250px",
					height: "64px",
				});
			}
		}, [onGoogleSignInSuccess, validToken]);

		const googleInit = useCallback(() => {
			const googleApi = document.createElement("script");
			googleApi.setAttribute("src", "https://accounts.google.com/gsi/client");
			googleApi.onload = () => initializeGSI();
			document.querySelector("body").appendChild(googleApi);
		}, [initializeGSI]);


		useEffect(() => {
			googleInit();
		}, [googleInit]);

		return <Button id='googleButton' />;
	}
};

export default GoogleAuth;