import axios from "axios";
import { useContext, useState, useMemo } from "react";

import { TokenValidated, LoginContext } from "components/Login/loginCommon";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({ baseURL });
axiosInstance.noCache = {
	"Cache-Control": "no-cache",
	"Pragma": "no-cache",
	"Expires": "0",
};

export default function useAdminTools() {
	const {
		setAccessToken,
		signOut,
	} = useContext(LoginContext);

	const [, setInterceptor] = useState(null);

	return useMemo(() => {
		setInterceptor((prevInterceptor) => {
			if (prevInterceptor) {
				axiosInstance.interceptors.request.eject(prevInterceptor);
			}
			return axiosInstance.interceptors.request.use(async (config) => {
				let accessToken = localStorage.getItem("login/access");
				let refreshToken = localStorage.getItem("login/refresh");
        
				if (!TokenValidated(accessToken)) {
					//Access token expired or not present; get new access token
					if (!TokenValidated(refreshToken)) {
						//Refresh token expired or not present; new login required
						config.headers["Authorization"] = "";
						signOut();
						return config;
					}
					const refreshAttempt = await axios.post(`${baseURL}/api/v1/auth/token`,null,
						{
							headers:{
								"Refresh-Token": `Admin ${refreshToken}`,
							}
						}
					);
					const tokenStr = refreshAttempt.data?.data?.access_token;
					const newToken = TokenValidated(tokenStr);
					if(newToken) {
						localStorage.setItem("login/access", tokenStr);
						localStorage.setItem("login/scope", newToken.scope);
						setAccessToken(tokenStr);
						accessToken = tokenStr;
					} else {
						signOut();
					}
				}
				config.headers["Authorization"] = `Bearer ${accessToken}`;
				return config;
			});
		});

		return axiosInstance;
	}, [signOut, setAccessToken]);
}
