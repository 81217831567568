import React from "react";

import { Stack, Alert, AlertTitle } from "@mui/material";
import "./AlertStack.css";

export function useAlertStack() {
	const [pageAlerts, setPageAlerts] = React.useState([]);

	const newAlert = (severity, content, title) => {
		let next = {};

		let handleClose = () => {
			setPageAlerts(prev => {
				prev.splice(prev.indexOf(next.alert), 1);
				return [...prev];
			});
		};

		if(title) {
			title = <AlertTitle>{title}</AlertTitle>;
		}

		next.alert = <Alert key={Math.random()} severity={severity} onClose={handleClose}>
			{title}
			{content}
		</Alert>;

		setPageAlerts(prev => {
			return [...prev, next.alert];
		});
	};
	const AlertStack = ()=><Stack spacing={2} id="alertStack">
		{pageAlerts}
	</Stack>;
	return [AlertStack,newAlert];
}