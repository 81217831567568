import React from "react";
import { useCallback } from "react";
import { DraggableCore } from "react-draggable";

import "./DateScrollBar.css";

const DateScrollBar = ({max, min, viewRange, position, onPositionChange}) => {

	const thumbRef = React.useRef();
	const trackRef = React.useRef();
	const wrapRef = React.useRef();

	const trackRange = max.getTime() - min.getTime();

	const [initialMouseX, setInitialMouseX] = React.useState(0);
	const [initialThumbX, setInitialThumbX] = React.useState(0);

	const calcWidth = useCallback(() => {
		return `${100 * viewRange / trackRange}%`;
	},[viewRange,trackRange]);

	const calcLeft = useCallback(() => {
		let inRange = position.getTime() - min.getTime();
		return `${100 * inRange / trackRange}%`;
	},[position,trackRange]);

	const CalculateNewPosition = (event) => {
		let track = trackRef.current.getBoundingClientRect();
		let thumb = thumbRef.current.getBoundingClientRect();

		let pixelRange = track.width - thumb.width;

		let diff = event.clientX - initialMouseX;

		let mousePosition = initialThumbX + diff - track.left;
		mousePosition = Math.max(0, Math.min(mousePosition, pixelRange)) / pixelRange;

		let timeRange = max.getTime() - viewRange - min.getTime();

		let newDate = min.getTime() + timeRange * mousePosition;

		newDate = new Date(newDate);

		onPositionChange?.(newDate);
	};

	const doDragStart = (e,) => {
		setInitialMouseX(e.clientX);
		setInitialThumbX(thumbRef.current.getBoundingClientRect().left);
	};

	const doDragMove = (e, target) => {
		CalculateNewPosition(e, target);
	};

	const doDragStop = (e, target) => {
		CalculateNewPosition(e, target);
	};

	return <div ref={wrapRef}>
		<div className="dateScrollBar" ref={trackRef}>
			<div className="dateScrollTrack" />
			<DraggableCore
				onStart={doDragStart}
				onDrag={doDragMove}
				onStop={doDragStop}
				nodeRef={thumbRef}
			>
				<div ref={thumbRef} className="dateScrollThumb"
					style={{ width: calcWidth(), left: calcLeft() }}
				/>
			</DraggableCore>
		</div>
	</div>;
};

export default DateScrollBar;
